<template>
    <div class="tortMonitorApplet">
        <div class="headrBox">

            <common-header></common-header>

        </div>

        <div class="headerBanner">

            <common-header-banner 
            :backgroundImage="backgroundImage"
            :title="title"
            :titleContent="titleContent">
            </common-header-banner>

        </div>

        <div class="contentBox">

            <div class="locationBox">
                <span>当前位置：</span>
                <el-breadcrumb separator=">">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>案例</el-breadcrumb-item>
                    <el-breadcrumb-item>商务服务</el-breadcrumb-item>
                    <el-breadcrumb-item>侵权监测小程序建设</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="projectShow">

                <!-- 介绍 -->
                <div class="introduceBox">

                    <div class="projectName">河北云察科技有限公司为<span>侵权监测</span>提供了小程序开发等一系列服务。</div>


                    <div class="typeBox">

                        <div class="typeTitle">网站类型：</div>
                        <div class="typeContent">小程序</div>

                    </div>

                    <div class="typeBox">

                        <div class="typeTitle">网站语言：</div>
                        <div class="typeContent">中文</div>

                    </div>

                    <div class="typeBox">

                        <div class="typeTitle">所属行业：</div>
                        <div class="typeContent">商务服务</div>

                    </div>

                    <div class="introduceWeb">网站介绍：系统采用最优的AI算法和训练数据，应用最新的深度学习模型，并与电子存证机构达成合作，可实现侵权线索快速检索、实时监控、确权取证三大功能。</div>

                    <!-- 图片内容 -->
                    <div class="imageContent">

                        <div class="webLogo">

                            <img src="../../assets/image/jiancejianxcx/icon-logo.png" alt="">

                        </div>

                        <div class="bannerOne">

                            <div class="imageOne"></div>
                            <div class="imagetwo"></div>

                        </div>

                        <div class="bannerTwo">

                            <div class="bannerTwoTitle">

                                <div class="titleEnglish">PAGE DISPLAY</div>

                                <div class="titleChinese">页面展示</div>

                            </div>

                            <div class="backBox">

                                <div class="imageThree">

                                    <div class="imageListBox">

                                        <div class="listContent">
                                            <div class="card" v-for="(item,index) in cardList" :key="'only' + index">
                                                <div class="cardImage">
                                                    <img :src="item.url" alt="">
                                                </div>
                                            </div>
                
                                            <div class="historylList" v-for="item in ( historyRow - cardList.length % historyRow)" :key="item">
                                                <div v-if="cardList.length % historyRow > 0"></div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="bottomBack">

                                    <div class="bottomBackBox">

                                        <div class="thanksBox">THANKS OF WATCHING</div>

                                        <div class="logoBox">
                                            <div class="logoBoxTwo">
                                                <img src="../../assets/image/logo/allWhite.png" alt="">
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div> 

        </div>

        <div class="CommonMoreCases">
            <common-more-cases></common-more-cases>
        </div>

        <conmmon-bottom></conmmon-bottom>
    </div>
</template>

<script>
import '../../styles/case/tortMonitorApplet.scss'
import CommonHeaderBanner from '@/components/CommonHeaderBanner.vue'
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import CommonMoreCases from '@/components/CommonMoreCases.vue'
import 'animate.css';

export default {
  components: {
    ConmmonBottom,
    CommonHeader,
    CommonHeaderBanner,
    CommonMoreCases
  },
  data() {
    return{
        backgroundImage: require('@/assets/image/jiancejianxcx/case_p1.png'),
        title: '侵权监测小程序建设',
        titleContent: '商务服务小程序建设·张家口小程序建设',
        cardList: [
            {
                url: require('@/assets/image/jiancejianxcx/case_p4.png')
            },
            {
                url: require('@/assets/image/jiancejianxcx/case_p5.png')
            },
            {
                url: require('@/assets/image/jiancejianxcx/case_p6.png')
            },
            {
                url: require('@/assets/image/jiancejianxcx/case_p7.png')
            },
            {
                url: require('@/assets/image/jiancejianxcx/case_p8.png')
            }
        ],
        historyRow: 3,
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
