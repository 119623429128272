<template>
    <div class="CommonHeader">

        <div class="openNav">
            <div class="openWord">MENU</div>
            <div class="openIcon">
                <i class="iconfont icon-daohang"></i>
            </div>
        </div>

        <div class="navList">
            <div 
                class="navCard" 
                v-for="(item,index) in navList" 
                :key="'only' + index" 
                @click="jumpPage(item)"
                :class="{ card_tabs : index == keyID }">{{item.title}}</div>
        </div>

        <div class="contact">
            <i class="iconfont icon-dianhuaNav"></i>
        </div>

    </div>
</template>

<script>
import '@/styles/assembly/CommonHeader.scss'

export default {
    data() {
        return {
            navList: [
                {
                    id: 0,
                    title: '首页',
                    path: '/',
                    anchors: 1
                },
                {
                    id: 1,
                    title: '关于我们',
                    path: '/adout',
                    anchors: 2
                },
                {
                    id: 2,
                    title: '产品中心',
                    path: '/product',
                    anchors: 4
                },
                {
                    id: 3,
                    title: '招商合作',
                    path: '/cooperation',
                    anchors: 5
                },
                // {
                //     id: 3,
                //     title: 'GSAP',
                //     path: '/homeIndexGsap',
                //     anchors: 5
                // }
            ],
            keyID: 0
        }
    },
    watch: {

    },
    created() {
        this.navList.map(item => {
            if (item.path == this.$route.path) {
                this.keyID = item.id
            }
        })
    },
    methods: {
        jumpPage(item) {
            this.$router.push({path: item.path})
            this.keyID = item.id
        }
    }
}
</script>

<style>

</style>