<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
#app,body{
  height: 100%;
  width: 100%;
}
</style>
