<template>
  <div class="patentData">
    <div class="headrBox">

      <common-header></common-header>

    </div>

    <div class="headerBanner">

      <common-header-banner 
        :backgroundImage="backgroundImage"
        :title="title"
        :titleContent="titleContent">
      </common-header-banner>

    </div>

    <div class="contentBox">

    <div class="locationBox">
      <span>当前位置：</span>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案例</el-breadcrumb-item>
        <el-breadcrumb-item>商务服务</el-breadcrumb-item>
        <el-breadcrumb-item>商务服务网站建设-云察专利数据服务平台</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="projectShow">

      <!-- 介绍 -->
      <div class="introduceBox">

        <div class="projectName">河北云察科技有限公司为<span>云察专利数据服务平台</span>提供专业网站定制服务。</div>


        <div class="typeBox">

          <div class="typeTitle">网站类型：</div>
          <div class="typeContent">平面设计网站</div>

        </div>

        <div class="typeBox">

          <div class="typeTitle">网站语言：</div>
          <div class="typeContent">中文</div>

        </div>

        <div class="typeBox">

          <div class="typeTitle">所属行业：</div>
          <div class="typeContent">商务服务</div>

        </div>

        <div class="introduceWeb">网站介绍：在线专利信息分析系统是由国家知识产权局为各地方知识产权局组织开发建设的，基于互联网环境使用的专利信息分析系统。本系统可实现二次开发、自由配置等功能，满足系统功能的特色化、个性化；用户的待分析数据只要符合系统数据格式要求，就可以导入系统进行分析，且默认自带全领域数据库数据格式导入接口。本系统分析功能包括数据清理与整合、申请人归并；基础分析（申请人、区域、技术领域、法律状态分析等）、高级分析（自定义分析）、定制分析（核心技术分析、申请人合作分析、技术发展及衍变趋势分析）及专业化模型分析（企业专利实力分析、企业发展定位分析等）等功能。本系统由国家知识产权局部署到全国各地方知识产权局，由国家知识产权局负责统一系统运维，各地方知识产权局负责系统的具体使用和推广。</div>

        <!-- 图片内容 -->
        <div class="imageContent">

          <div class="webLogo">

            <img src="../../assets/image/yunchazhuanli/logo.png" alt="">

          </div>

          <div class="bannerOne">

            <div class="imageOne"></div>
            <div class="imagetwo"></div>

          </div>

          <div class="bannerTwo">

            <div class="bannerTwoTitle">

              <div class="titleEnglish">PAGE DISPLAY</div>

              <div class="titleChinese">页面展示</div>

            </div>

            <div class="backBox">

              <div class="imageThree">
                <div class="imageThree_one">
                  <!-- <el-image :src="image_one" fit="contain" alt></el-image> -->
                  <img src="../../assets/image/yunchazhuanli/case_p4.png"  alt="">
                </div>
                <div class="imageThree_two">
                  <img src="../../assets/image/yunchazhuanli/case_p5.png" alt="">
                </div>
                <div class="imageThree_three">
                  <img src="../../assets/image/yunchazhuanli/case_p6.png" alt="">
                </div>
              </div>

              <div class="bottomBack">

                <div class="bottomBackBox">

                  <div class="thanksBox">THANKS OF WATCHING</div>

                  <div class="logoBox">
                    <div class="logoBoxTwo">
                      <img src="../../assets/image/logo/allWhite.png" alt="">
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div> 

    </div>

    <div class="CommonMoreCases">
    <common-more-cases></common-more-cases>
    </div>

    <conmmon-bottom></conmmon-bottom>
  </div>
  
</template>

<script>
import '../../styles/case/patentData.scss'
import CommonHeaderBanner from '@/components/CommonHeaderBanner.vue'
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import CommonMoreCases from '@/components/CommonMoreCases.vue'
import 'animate.css';

export default {
  components: {
    ConmmonBottom,
    CommonHeader,
    CommonHeaderBanner,
    CommonMoreCases
  },
  data() {
    return{
      backgroundImage: require('@/assets/image/yunchazhuanli/case_p1.png'),
      image_one: require('@/assets/image/yunchazhuanli/case_p5.png'),
      title: '商务服务网站建设-云察专利数据服务平台',
      titleContent: '商务服务网站建设·张家口网站建设'
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
