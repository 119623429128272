<template>
    <div class="goshaApp">

        <div class="headrBox">

            <common-header></common-header> 

        </div>

        <div class="headerBanner">

            <common-header-banner 
            :backgroundImage="backgroundImage"
            :title="title"
            :titleContent="titleContent">
            </common-header-banner>

        </div>

        <div class="contentBox">

            <div class="locationBox">
                <span>当前位置：</span>
                <el-breadcrumb separator=">">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>案例</el-breadcrumb-item>
                    <el-breadcrumb-item>公益</el-breadcrumb-item>
                    <el-breadcrumb-item>科技APP建设-善治社区APP/小程序</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="projectShow">

                <!-- 介绍 -->
                <div class="introduceBox">

                    <div class="projectName">河北云察科技有限公司为<span>张家口慈善联合会</span>提供了APP设计、小程序开发等一系列服务。</div>


                    <div class="typeBox">

                        <div class="typeTitle">网站类型：</div>
                        <div class="typeContent">APP/小程序</div>

                    </div>

                    <div class="typeBox">

                        <div class="typeTitle">网站语言：</div>
                        <div class="typeContent">中文</div>

                    </div>

                    <div class="typeBox">

                        <div class="typeTitle">所属行业：</div>
                        <div class="typeContent">公益/志愿服务</div>

                    </div>

                    <div class="introduceWeb">网站介绍：五社善治志愿服务回馈平台创建于2022年9月，是通过记录志愿服务工时，以工时兑换服务形式，致力于打造创新志愿服务回馈模式，促进志愿服务长期有效可持续发展。目前涵盖志愿服务、社区服务、养老服务、物业服务、爱心商城、时间银行等业务板块。平台立足于社区，以“服务一群人，温暖一座城”为理念，旨在通过社会创新的可持续发展模式，发展成为社区服务领域的创新平台。</div>

                    <!-- 图片内容 -->
                    <div class="imageContent">

                        <div class="webLogo">

                            <img src="../../assets/image/wusheshanzhi/icon_logo.png" alt="">

                        </div>

                        <div class="bannerOne">

                            <div class="imageOne"></div>
                            <div class="imagetwo"></div>

                        </div>

                        <div class="bannerTwo">

                            <div class="bannerTwoTitle">

                                <div class="titleEnglish">PAGE DISPLAY</div>

                                <div class="titleChinese">页面展示</div>

                            </div>

                            <div class="backBox">

                                <div class="imageThree">

                                    <div class="imageListBox">

                                        <div class="listContent">
                                            <div class="card" v-for="(item,index) in cardList" :key="'only' + index">
                                                <div class="cardImage">
                                                    <img :src="item.url" alt="">
                                                </div>
                                            </div>
                
                                            <div class="historylList" v-for="item in ( historyRow - cardList.length % historyRow)" :key="item">
                                                <div v-if="cardList.length % historyRow > 0"></div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="bottomBack">

                                    <div class="bottomBackBox">

                                        <div class="thanksBox">THANKS OF WATCHING</div>

                                        <div class="logoBox">
                                            <div class="logoBoxTwo">
                                                <img src="../../assets/image/logo/allWhite.png" alt="">
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div> 

        </div>

        <div class="CommonMoreCases">
            <common-more-cases></common-more-cases>
        </div>

        <conmmon-bottom></conmmon-bottom>

    </div>
  

</template>

<script>
import '../../styles/case/goshaApp.scss'
import CommonHeaderBanner from '@/components/CommonHeaderBanner.vue'
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import CommonMoreCases from '@/components/CommonMoreCases.vue'
import 'animate.css';

export default {
  components: {
    ConmmonBottom,
    CommonHeader,
    CommonHeaderBanner,
    CommonMoreCases
  },
  data() {
    return{
        backgroundImage: require('@/assets/image/wusheshanzhi/case_p1.png'),
        title: '科技网站建设-善治社区APP',
        titleContent: '科技APP建设·张家口APP/小程序建设',
        cardList: [
            {
                url: require('@/assets/image/wusheshanzhi/case_p4.png')
            },
            {
                url: require('@/assets/image/wusheshanzhi/case_p5.png')
            },
            {
                url: require('@/assets/image/wusheshanzhi/case_p6.png')
            },
            {
                url: require('@/assets/image/wusheshanzhi/case_p7.png')
            },
            {
                url: require('@/assets/image/wusheshanzhi/case_p8.png')
            },
            {
                url: require('@/assets/image/wusheshanzhi/case_p9.png')
            }
        ],
        historyRow: 3,
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
