<template>
    <div class="commonHeaderBanner" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">

        <!-- logo 和 电话 -->
        <div class="logoAndPhone">

            <div class="logoBox" :class="'animate__animated' + '  ' + titleAnimation">
                <img src="../assets/image/logo/allWhite.png" alt="">
            </div>

            <div class="phoneBox" :class="'animate__animated' + '  ' + titleAnimation">
                <i class="iconfont icon-dianhua"> 133-3118-3155</i>
            </div>

        </div> 

        <!-- 标题内容 -->
        <div class="titleContent">

            <div class="headerLine"></div>

            <div class="headerTitle">{{title}}</div>

            <div class="headerWord">{{titleContent}}</div>

        </div>

        <!-- 导航 -->
        <div class="navBox">

            <div class="shareBox">

                <i class="iconfont icon-share2"></i>

            </div>

        </div>

    </div>
</template>

<script>
import '../styles/assembly/commonHeaderBanner.scss'
export default {
    props: {
        backgroundImage: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        titleContent: {
            type: String,
            required: true,
        },
    },
}
</script>

<style>

</style>