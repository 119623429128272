<!-- <template>
    <div class="homeIndexGsap">
        <div class="screen section1">

            <div class="headerSwiper">
                <el-carousel :interval="5000" arrow="never" :height="bannerHeight + 'px'" @change="obtainState">

                    <el-carousel-item>

                        <div class="bannerOne">

                            <div class="middleBox">

                                <div class="whiteBox">

                                    <div class="polygonOne">
                                        <div class="polygonOneBox">
                                            <img src="../../assets/image/polygon.png">
                                        </div>
                                    </div>
                                    <div class="polygonTwo">
                                        <img src="../../assets/image/polygon.png">
                                    </div>

                                    <div class="content">
                                        
                                        <div class="company">云察科技有限公司</div>

                                        <div class="titleBox">不止步 向前进</div>

                                        <div class="english">BUZHIBUXIANGQIANZOU</div>

                                        <div class="line"></div>

                                    </div>

                                </div>

                            </div>

                            <div class="welcomeBox">

                                <div class="origin">●</div>

                                <div class="wordBox"> WELCOME</div>

                                <div class="line"></div> 

                                <div class="arrow">
                                    <img src="../../assets/image/forward.png" alt="">
                                </div>

                            </div>

                        </div>

                    </el-carousel-item>

                    <el-carousel-item>
                        
                        <div class="bannerTwo">

                            <div class="middleBox">

                                <div class="titleImage">

                                    <img src="../../assets/image/title2.png" alt="">

                                </div>

                                <div class="longSentence">诚立天下 信赢未来诚立天下 信赢未来诚立天下 信赢未来诚立天下 信赢未来</div>

                                <div class="originBtn">
                                    <div class="originBtnImage">
                                        <img src="../../assets/image/arrow.png" alt="">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </el-carousel-item>

                    <el-carousel-item>
                        
                        <div class="bannerThree">
                            <div class="middleBox">

                                <div class="titleImage">

                                    <img src="../../assets/image/title3.png" alt="">

                                </div>

                                <div class="longSentence">凭借精细、精准的细节来创业 凭借优质、优秀的品质来取胜凭借精</div>

                                <div class="originBtn"></div>
                            </div>
                        </div>

                    </el-carousel-item>



                </el-carousel>
            </div>

        </div>
        <div class="screen section2">
        </div>
    </div>
</template>

<script>
import '@/styles/mainModule/homeIndexGsap.scss'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
export default {
    data() {
        return {
            bannerHeight: '',
            bodyHeight: '',
            screenWidth: '',
            screenHeight: '',
        }
    },
    watch: {
        //窗口高度变化
        screenHeight (val) {
            this.bannerHeight = val
        }
    },
    mounted() {
        // 监听窗口发生变化
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth;
                this.screenHeight = document.body.clientHeight;
            })();
        };
        this.init()
    },
    methods: {
        init() {
            ScrollTrigger.create({
                trigger: '.section1',
                start: 'top top',
                end: '+=1000',
                scrub: true,
                animation: gsap
                    .timeline()
                    .fromTo('.headerSwiper', { scale: 1 }, { scale: 0.5 })
                    // .fromTo('.summary-content', { width: '50%', height: '50vh' }, { width: '100%', height: '100vh' }, '<')
            })
        }
    }
}
</script>

<style>

</style> -->
<template>
    <div style="height: 100%;">
      <div class="container">
        <div class="image-container">
          <img src="../../assets/image/background.png" alt="Image" class="hidden">
        </div>
        <div class="highlight-area" :style="{ width: highlightWidth + 'px', height: highlightHeight + 'px', left: highlightLeft + 'px', top: highlightTop + 'px' }" v-show="highlightVisible"></div>
      </div>
      <button @click="moveHighlightArea">移动高亮区域</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        highlightWidth: 0, // 高亮区域的宽度
        highlightHeight: 0, // 高亮区域的高度
        highlightLeft: 0, // 高亮区域的左侧位置
        highlightTop: 0, // 高亮区域的顶部位置
        highlightVisible: false // 控制高亮区域的显示与隐藏
      };
    },
    mounted() {
      // 获取图片容器的宽度和高度，以设置高亮区域的大小
      const imageContainer = document.querySelector('.image-container');
      this.highlightWidth = imageContainer.offsetWidth;
      this.highlightHeight = imageContainer.offsetHeight;
    },
    methods: {
      moveHighlightArea() {
        // 每次点击按钮后重置高亮区域位置
        this.highlightLeft = 0; // 初始位置在图片左侧
        this.highlightVisible = true; // 显示高亮区域
        // 使用动画让高亮区域从左向右移动过程中显示在图片上
        setTimeout(() => {
          this.highlightLeft = this.highlightWidth; // 移动到图片的右侧
        }, 100);
      }
    }
  };
  </script>
  
  <style>
  .container {
    position: relative;
    height: 100%;
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .hidden {
    display: none; /* 隐藏图片 */
  }
  
  .highlight-area {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 0, 0.5); /* 设置高亮区域的背景颜色 */
    pointer-events: none; /* 高亮区域不响应鼠标事件 */
    transition: left 1s; /* 设置过渡效果，让高亮区域从左向右移动 */
  }
  </style>
  
  