<template>
    <div class="homeIndex">
        <div class="headrBox">
            <common-header></common-header>
        </div>
        <div class="timeAxis">
            
            <div class="timeAxisBox">

                <div class="timeLineBox">

                    <div 
                        class="horizontalLine" 
                        v-for="(item,index) in 83" 
                        :key="'only' + index"
                        @mouseenter="lineEnter(item,index)" 
                        @mouseleave="lineLeave(item,index)"
                        :class="index == styleIndex ? styleLine : ''"
                        @click="jumpPage(item,index)">
                        <div 
                            class="line" 
                            :style="{
                                backgroundColor: (index == roundNumber - 1 ? '#E93329' : lineColor),
                                width: (index == roundNumber - 1 ? '20px' : '')}">
                        </div>
                    </div>

                </div>
                <div class="timeRoundBox">
                    <!-- 107 -->
                    <div 
                        class="roundBox" 
                        v-for="(item,index) in 83" 
                        :key="'only' + index"
                        @mouseenter="lineEnter(item,index)" 
                        @mouseleave="lineLeave(item,index)"
                        :class="index == styleIndex ? styleLine : ''"
                        @click="jumpPage(item,index)">
                        <div 
                            class="round" 
                            :style="{backgroundColor: (index == roundNumber - 1 ? '#E93329' : lineColor)}"></div>
                    </div>
                </div>
                <div class="timeTitleBox">
                    <div 
                        class="titleBox" 
                        v-for="(item,index) in horizontalLine" 
                        :key="'only' + index"
                        @mouseenter="lineEnter(item,index)" 
                        @mouseleave="lineLeave(item,index)"
                        :class="index == titleIndex ? styleLine : ''"
                        @click="jumpTitlePage(item,index)">
                        <div 
                            class="title"
                            :style="{display:(item.nodeWord == anchorTitle ? 'block' : 'none')}">{{item.title}}</div>
                    </div>
                </div>

            </div>

        </div> 
        <div class="homeContent">
            <div class="logoAndPhone">
                
                <div class="logoBox">
                    <img :src="logo">
                </div>

                <div class="phoneWord">
                    <i class="iconfont icon-dianhua" :style="'color:' + phoneColor"> 133-3118-3155</i>
                </div>

            </div>
            <full-page :options="options" ref="fullpage" id="fullpage">
                <div class="section">

                    <div class="headerSwiper">
                        <el-carousel :interval="5000" arrow="never" :height="bannerHeight + 'px'" @change="obtainState">
    
                            <el-carousel-item>
    
                                <div class="bannerOne">

                                    <div class="middleBox">

                                        <div class="whiteBox">

                                            <div class="polygonOne">
                                                <div class="polygonOneBox">
                                                    <img src="../../assets/image/polygon.png">
                                                </div>
                                            </div>
                                            <div class="polygonTwo">
                                                <img src="../../assets/image/polygon.png">
                                            </div>

                                            <div class="content">
                                                
                                                <div class="company">云察科技有限公司</div>

                                                <div class="titleBox">不止步 向前进</div>

                                                <div class="english">BUZHIBUXIANGQIANZOU</div>

                                                <div class="line"></div>
    
                                            </div>

                                        </div>

                                    </div>

                                    <div class="welcomeBox">

                                        <div class="origin">●</div>

                                        <div class="wordBox"> WELCOME</div>

                                        <div class="line"></div> 

                                        <div class="arrow">
                                            <img src="../../assets/image/forward.png" alt="">
                                        </div>

                                    </div>

                                </div>
    
                            </el-carousel-item>

                            <el-carousel-item>
                                
                                <div class="bannerTwo">

                                    <div class="middleBox">

                                        <div class="titleImage">

                                            <img src="../../assets/image/title2.png" alt="">

                                        </div>

                                        <div class="longSentence">诚立天下 信赢未来诚立天下 信赢未来诚立天下 信赢未来诚立天下 信赢未来</div>

                                        <div class="originBtn">
                                            <div class="originBtnImage">
                                                <img src="../../assets/image/arrow.png" alt="">
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </el-carousel-item>

                            <el-carousel-item>
                                
                                <div class="bannerThree">
                                    <div class="middleBox">

                                        <div class="titleImage">

                                            <img src="../../assets/image/title3.png" alt="">

                                        </div>

                                        <div class="longSentence">凭借精细、精准的细节来创业 凭借优质、优秀的品质来取胜凭借精</div>

                                        <div class="originBtn"></div>
                                    </div>
                                </div>

                            </el-carousel-item>
    
    
    
                        </el-carousel>
                    </div>

                </div>
                <div class="section">
                    
                    <div class="aboutUs">
                        

                        <div class="aboutUsBox">

                            <div class="aboutUsTitleBox">
                                <div class="title">关于我们</div>
                                <div class="line"></div>
                            </div>

                            <div class="companyName">河北云察科技有限公司</div>

                            <div class="introduce">河北云察科技有限公司（简称“云察”）成立于2022年9月5日，注册资本<br/>为500万元人民币，公司位于河北省张家口市高新区纬三路3号新东亚财富中心B座8楼26号<br/>主营： 翻译服务、开发、机械设备、进行市场调查、市场调研。</div>

                            <div class="labelBox">

                                <div class="labelCard" v-for="(item,index) in labelList" :key="'only' + index">

                                    <div class="babelTitle">
                                        <div class="wordOne">{{item.title}}</div>
                                        <div class="wordTwo">+</div>
                                    </div>

                                    <div class="short">{{item.short}}</div>

                                </div>

                            </div>

                            <div class="skillBox">

                                <div class="skillCard" v-for="(item,index) in skillList" :key="'only' + index">

                                    <div class="skillIconBox">
                                        <div class="skillIcon">
                                            <img :src="item.image">
                                        </div>
                                    </div>

                                    <div class="skillTitle">{{item.title}}</div>

                                </div>

                            </div>

                            <div class="welcomeAboutUs">

                                <div class="origin">●</div>

                                <div class="wordBox"> WELCOME</div>

                                <div class="line"></div> 

                                <div class="arrow">
                                    <img src="../../assets/image/forward.png" alt="">
                                </div>

                            </div>

                        </div>


                    </div>

                </div>
                <div class="section">
    
                    <div class="character">


                        <div class="characterContent">

                            <div class="contentTOP">

                                <div class="characterInfoBox">

                                    <div class="namePositionLine">

                                        <div class="name">{{aboutUsData.title}}</div>
                                        <div class="position">（{{aboutUsData.position}}）</div>
                                        <div class="line"></div>

                                    </div>

                                    <div class="responsibility">职责：{{aboutUsData.responsibility}}</div>

                                    <div class="onDutyTime">从业经验：{{aboutUsData.onDutyTime}}</div>
                                    <div class="education">教育背景：{{aboutUsData.education}}</div>
                                    <div class="practiceBackground">{{aboutUsData.practiceBackground}}</div>


                                </div>

                                <div class="characterIlluminate">

                                    <img :src="aboutUsData.image" alt="">

                                </div>

                            </div>
                            <div class="contentBottom">

                                <div class="leftArrow">
                                    <div class="originBtnImage" @click="toggleFun(-1)">
                                        <img src="../../assets/image/retreat.png" alt="">
                                    </div>
                                </div>
                                <div class="middleArrow">

                                    <div class="middleCard" v-for="(item,index) in userList" :key="'only' + index">

                                        <div class="portrait">

                                            <img :src="item.image" alt="">

                                        </div>

                                        <div class="userContent" v-show="item.show" :style="item.animation">
                                            <div class="titile">{{ item.title }}</div>

                                            <div class="titleEnglish">{{item.titleEnglish}}</div>

                                            <div class="position">职位：{{item.position}}</div>
                                        </div>

                                    </div>

                                </div>
                                <div class="rightArrow">
                                    <div class="originBtnImage" @click="toggleFun(1)">
                                        <img src="../../assets/image/arrow.png" alt="">
                                    </div>
                                </div>

                            </div>

                        </div>



                    </div>

                </div>
                <div class="section">

                    <div class="product">

                        <div class="productLeft">
                            <div class="productLeftPlace">

                                <div class="productTitleLine">
    
                                    <div class="titleBox">产品中心</div>
    
                                    <div class="lineBox"></div>
    
                                </div>
    
                                <div class="intentionBox">优秀的作品，只为同样令人惊喜的客户<br/>云察口号云察口号云察口号</div>
    
                                <div class="tabulation">
    
                                    <div class="tabulationCard" v-for="(item,index) in tabulationList" :key="'only' + index">
    
                                        <div class="tabulationLine">
    
                                            <div class="tabulationIcon">
                                                <img :src="item.image" alt="">
                                            </div>
    
                                            <div class="lineBox"></div>
    
                                            <div class="tabulationWord">
    
                                                <div class="wordTitle">{{item.title}}</div>
                                                <div class="wordEnglish">{{item.english}}</div>
    
                                            </div>
    
                                        </div>
    
                                        <div class="verticalLineBox">
                                            <div class="verticalLine"></div>
                                        </div>
    
                                    </div>
    
                                </div> 
    
                                <div class="originBtn">
                                    <div class="originBtnImage">
                                        <img src="../../assets/image/arrow.png" alt="">
                                    </div>
                                </div>


                            </div>
                            

                        </div>
                        <div class="productRight">

                            <div class="caseList">

                                <div 
                                    class="caseCard" 
                                    v-for="(item,index) in caseList"
                                    :key="'only' + index" 
                                    @mouseenter="caseEnter(item,index)" 
                                    @mouseleave="caseLeave(item,index)"
                                    :style="{backgroundImage: `url(${item.image})`}">

                                    <div 
                                        class="viewBtn" 
                                        @click="toDetail(item)"
                                        :style="{display: item.show}"
                                        :class="'animate__animated' + '  ' + item.animateBtn">查看详情</div>

                                    <div 
                                        class="introduce" 
                                        :style="{display: item.show}"
                                        :class="'animate__animated' + '  ' + item.animateTitle">{{item.title}}</div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div class="section">

                    <div class="cooperate">

                        <div class="cooperateContent">

                            <div class="locateContent">

                                <div class="locateLeft">

                                    <div class="locateLeftTitle">

                                        <div class="title">招商合作</div>

                                        <div class="line"></div>

                                    </div>

                                    <div class="locateLeftTitleInfo">予信任，寄惊喜。我们期待您的来信</div> 

                                    <div class="fromBox">

                                        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" :inline="true">
                 
                                            <div class="single">

                                                <div class="singleTitle">您的姓名</div>

                                                <div class="singleContent">
                                                    <el-form-item class="goods_item">
                                                        <el-input :border="false" v-model="ruleForm.name"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </div>

                                            <div class="singleLocate">

                                                <div class="singleTitle">您的电话</div>

                                                <div class="singleContent">
                                                    <el-form-item class="goods_item">
                                                        <el-input :border="false" v-model="ruleForm.phone"></el-input>
                                                    </el-form-item>
                                                </div>

                                            </div>

                                            <div class="singleLocateTwo">
                                                <div class="singleTitle">留言给我们</div>

                                                <div class="singleContent">
                                                    <el-form-item class="goods_item">
                                                        <el-input type="textarea" :border="false" v-model="ruleForm.message"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </div> 


                                            <div class="sending">

                                                <div class="sendingBtn"></div>

                                            </div>

                                        </el-form>

                                    </div>

                                </div>
                                <div class="locateRight">

                                    <div class="locateRightTitle">HOW TO FIND US?</div>
                                    <div class="welcomeTitle">我们为您准备好了您喜欢的咖啡和舒适的环境<br/>赶紧通过以下方式找到我们</div>
                                    <div class="intention">若您有合作意向，请您使用以下方式联系我们，您给我们多大的信任，我们给您多大的惊喜</div>
                                    
                                    <div class="contactInfo">

                                        <div class="contactInfoCard" v-for="(item,index) in contactList" :key="'only' + index">

                                            <div class="IconBox">
                                                <div class="contactIcon">
                                                    <img :src="item.image">
                                                </div>
                                            </div>

                                            <div class="contactTitle" v-html="item.title"></div>

                                        </div>

                                    </div>

                                    <div class="contactLine">

                                        <div class="qqAndweixin">
                                                
                                            <i  
                                                @mouseenter="qqIconEnter" 
                                                @mouseleave="qqIconLeave"
                                                :class="'iconfont ' + qqIcon"></i>
                                            <i 
                                                @mouseenter="weixinIconEnter" 
                                                @mouseleave="weixinIconLeave"
                                                :class="'iconfont ' + weixinIcon"></i>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="cooperateBottom">
                            <conmmon-bottom></conmmon-bottom>
                        </div>

                    </div>

                </div>
                <!-- <div class="section fp-auto-height">
                    <conmmon-bottom></conmmon-bottom>
                </div> -->
            </full-page>
        </div>
    </div>
</template>

<script>
    import '@/styles/mainModule/homeIndex.scss'
    import ConmmonBottom from '@/components/ConmmonBottom.vue'
    import CommonHeader from '@/components/CommonHeader.vue'
    import 'animate.css';
    export default {
        components: { 
            ConmmonBottom,
            CommonHeader
        },
        data() {
            return {
                // logo
                logo: require('@/assets/image/logo/red_white.png'),
                phoneColor: '#FFFFFF',
                options: {
                    licenseKey: 'YOUR_KEY_HEERE',
                    //是否显示导航，默认为false
                    navigation: false,
                    afterLoad: this.afterLoad,
                    anchors: ["banner", "adout", "character", "product","cooperate"],
                    // scrollOverflow: true,
                    // scrollBar: false,
                    //为每个section设置背景色
                    sectionsColor: [
                        "#FFFFFF",
                        "#FFFFFF",
                        "#FFFFFF",
                        "#FFFFFF",
                        "#FFFFFF",
                    ],
                },
                bannerHeight:"",
                bodyHeight: '',
                screenWidth: '',
                screenHeight: '',
                anchorTitle: 'banner',
                lineColor: '#FFFFFF',
                roundNumber: 6,
                isHovered: false,
                horizontalLine: [
                    {
                        title: '首页',
                        nodeWord: 'banner',
                        show: true,
                        id: 0
                    },
                    {
                        title: '关于我们',
                        nodeWord: 'adout',
                        show: false,
                        id: 1
                    },
                    {
                        title: '团队介绍',
                        nodeWord: 'character',
                        show: false,
                        id: 2
                    },
                    {
                        title: '产品中心',
                        nodeWord: 'product',
                        show: false,
                        id: 3
                    },
                    {
                        title: '招商合作',
                        nodeWord: 'cooperate',
                        show: false,
                        id: 4
                    },
                ],
                styleLine: '',
                styleIndex: null,
                titleIndex: null,
                labelList: [
                    {
                        id: 0,
                        title: '诚信',
                        short: '诚立天下，信赢未来。'
                    },
                    {
                        id: 1,
                        title: '专业',
                        short: '业有专攻，精益求精。'
                    },
                    {
                        id: 2,
                        title: '共赢',
                        short: '志同道合，互利共赢。'
                    }
                ],
                skillList: [
                    {
                        id: 0,
                        title: '翻译服务',
                        image: require('@/assets/image/Translation.png')
                    },
                    {
                        id: 1,
                        title: '开发',
                        image: require('@/assets/image/develop.png')
                    },
                    {
                        id: 2,
                        title: '机械设备',
                        image: require('@/assets/image/device.png')
                    },
                    {
                        id: 3,
                        title: '进行市场调查',
                        image: require('@/assets/image/4.png')
                    },
                    {
                        id: 4,
                        title: '市场调研',
                        image: require('@/assets/image/survey.png')
                    }
                ],
                ruleForm: {
                    name: '',
                    phone: '',
                    message: ''
                },
                rules: {

                },
                contactList: [
                    {
                        id: 0,
                        title: `张家口市高新区纬三路 3 号<br/>新东亚财富中心B座8楼26号`,
                        image: require('@/assets/image/address.png')
                    },
                    {
                        id: 1,
                        title: `phone:<br/>13331183155（徐经理）`,
                        image: require('@/assets/image/phone1.png')
                    },
                    {
                        id: 2,
                        title: `EMAIL:<br/>13331183155@163.com`,
                        image: require('@/assets/image/emall.png')
                    }
                ],
                qqIcon: 'icon-QQ',
                weixinIcon: 'icon-weixin',
                tabulationList: [
                    {
                        id: 0,
                        title: '网站设计',
                        english: 'WEBSITE DESIGN',
                        image: require('@/assets/image/homeProduct/websitedesign.png')
                    },
                    {
                        id: 1,
                        title: 'APP设计',
                        english: 'APP DESIGN',
                        image: require('@/assets/image/homeProduct/appdesign.png')
                    },
                    {
                        id: 2,
                        title: '小程序设计',
                        english: 'MINI PROGRAM DESIGN',
                        image: require('@/assets/image/homeProduct/minipro.png')
                    },
                    {
                        id: 3,
                        title: 'LOGO设计',
                        english: 'LOGO DESIGN',
                        image: require('@/assets/image/homeProduct/logodesign.png')
                    }
                ],
                caseList: [
                    {
                        id: 0,
                        path:'/goshaApp',
                        image: require('@/assets/image/case_p1.png'),
                        time: '2023-12-22 15:50',
                        title: '网站建设-五社善治',
                        word: '五社善治APP是一款用于志愿服务为主体的应用。志愿者可以建设组织团队,进行活动的发布和报名;为了更多志愿者加入其中,五社善治还联合当地的超市进行一些优惠特价服务提供给志愿者。',
                        show: 'none',
                        animateBtn: '',
                        animateTitle: '',
                    },
                    {
                        id: 1,
                        path:'/yingmaiqi',
                        image: require('@/assets/image/case_p2.png'),
                        time: '2023-12-22 15:50',
                        title: '网站建设-英迈琪科技有限公司',
                        word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                        show: 'none',
                        animateBtn: '',
                        animateTitle: '',
                    },
                    {
                        id: 2,
                        path:'/tortMonitorApplet',
                        image: require('@/assets/image/case_p3.png'),
                        time: '2023-12-22 15:50',
                        title: '商务服务小程序建设-侵权监测小程序',
                        word: '系统采用最优的AI算法和训练数据，应用最新的深度学习模型，并与电子存证机构达成合作，可实现侵权线索快速检索、实时监控、确权取证三大功能。',
                        show: 'none',
                        animateBtn: '',
                        animateTitle: '',
                    },
                    {
                        id: 3,
                        path:'/tortMonitor',
                        image: require('@/assets/image/case_p4.png'),
                        time: '2023-12-22 15:50',
                        title: '商务网站建设-侵权电子证据系统',
                        word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                        show: 'none',
                        animateBtn: '',
                        animateTitle: '',
                    },
                    {
                        id: 4,
                        image: require('@/assets/image/case_p5.png'),
                        time: '2023-12-22 15:50',
                        path:'/patentData',
                        title: '网站建设-云察专利服务平台',
                        word: '平台引入本地专利数据，提供检索和查询服务，并为客户对接相关专利信息提供平台。',
                        show: 'none',
                        animateBtn: '',
                        animateTitle: '',
                    },
                    // {
                    //     id: 5,
                    //     image: require('@/assets/image/case_p6.png'),
                    //     time: '2023-12-22 15:50',
                    //     title: '志愿服务小程序建设-五社善治',
                    //     word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                    // },
                    // {
                    //     id: 6,
                    //     image: require('@/assets/image/case_p7.png'),
                    //     time: '2023-12-22 15:50',
                    //     title: '教育网站建设-墨以AI教学',
                    //     word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                    // },
                    {
                        id: 7,
                        path:'/sendByPost',
                        image: require('@/assets/image/case_p8.png'),
                        time: '2023-12-22 15:50',
                        title: '运输服务建设-张运速递',
                        word: '张云速递APP是一款快递物流综合服务APP。客货邮已形成地网和信息网两网合一、可覆盖国内大部分地区。客货邮采用直营的经营模式,对分支机构实施统一经营、统一管理。',
                        show: 'none',
                        animateBtn: '',
                        animateTitle: '',
                    },
                    // {
                    //     id: 8,
                    //     image: require('@/assets/image/case_p9.png'),
                    //     time: '2023-12-22 15:50',
                    //     title: '教育网站建设-河北建筑工程学院',
                    //     word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                    // },
                    {
                        id: 9,
                        path:'/sendByPostApplet',
                        image: require('@/assets/image/case_p10.png'),
                        time: '2023-12-22 15:50',
                        title: '运输服务小程序建设-张运速递',
                        word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                        show: 'none',
                        animateBtn: '',
                        animateTitle: '',
                    },
                    
                    
                ],
                userList: [
                    // {
                    //     id: 0,
                    //     title: '司亚超',
                    //     image: '',
                    //     onDutyTime: '20年',
                    //     education: '河北工业大学 硕士',
                    //     practiceBackground: '河北建筑工程学院副教授，硕士生导师，物联网工程教研室主任，张家口市物联网技术创新中心负责人。先后负责省市级科研项目多项，发表EI等高水平论文多篇，具有丰富的计算机领域科学研究、软件开发及项目管理经验。',
                    //     responsibility: '战略规划，投融资',
                    //     titleEnglish: 'Si-YaChao',
                    //     position: '总经理',
                    //     show: true,
                    //     animation: '',
                    // },
                    {
                        id: 0,
                        title: '徐小君',
                        image: require('../../assets/image/xu.jpg'),
                        onDutyTime: '20年',
                        education: '北京邮电大学 博士',
                        practiceBackground: '美国上市公司KLA 北京分公司负责人。曾供职于超星数字图书馆软件开发。先后负责多个图像处理及人工智能项目，参加NIST-TRECVID评测。具有丰富的软件开发及项目管理经验。',
                        responsibility: '研发管理，运营管理',
                        titleEnglish: 'Xu-XiaoJun',
                        position: '研发部经理',
                        show: true,
                        animation: '',
                    },
                    {
                        id: 1,
                        title: '赵明瞻',
                        image: require('../../assets/image/zhao.jpg'),
                        onDutyTime: '20年',
                        education: '北京科技大学 博士',
                        practiceBackground: '从事人工智能算法开发多年，先后参与民政部及河北省科技厅多个项目，具有丰富的算法开发及项目管理经验。',
                        responsibility: '人工智能算法研究',
                        titleEnglish: 'Zhao-MingZhan',
                        position: 'AI部经理',
                        show: false,
                        animation: '',
                    },
                    {
                        id: 2,
                        title: '付江龙',
                        image: require('../../assets/image/fu.jpg'),
                        onDutyTime: '9年',
                        education: '西北工业大学 硕士',
                        practiceBackground: '从事软件开发多年，先后参与国家“核高基”项目、河北省科技厅项目等多个项目，具有丰富的软件开发及项目管理经验。',
                        responsibility: '技术研发，系统开发',
                        titleEnglish: 'Fu-JiangLong',
                        position: '技术部经理',
                        show: false,
                        animation: '',
                    },
                    // {
                    //     id: 4,
                    //     title: '周旭',
                    //     image: '',
                    //     onDutyTime: '5年',
                    //     education: '河北建筑工程学院',
                    //     practiceBackground: '从事软件开发多年，具有丰富的软件开发及项目管理经验。',
                    //     responsibility: '技术研发，系统开发',
                    //     titleEnglish: 'Zhou-Xu',
                    //     position: '技术部经理',
                    //     show: false,
                    //     animation: '',
                    // },
                    // {
                    //     id: 4,
                    //     title: '李劭杰',
                    //     image: '',
                    //     onDutyTime: '8年',
                    //     education: '武汉邮电科学研究院 硕士',
                    //     practiceBackground: '南京烽火星空通信发展有限公司 IAO部门和江苏研发中心。协助江苏、广西、四川公安完成过网络舆情分析和网络安全分析。主研烟盒缺陷检测系统和动力车间设备智能点检系统。',
                    //     responsibility: '市场营销，财务审核',
                    //     titleEnglish: 'Li-ShaoJie',
                    //     position: '市场部经理',
                    //     show: false,
                    //     animation: ''
                    // },
                ],
                aboutUsData: {
                    // id: 0,
                    // title: '司亚超',
                    // image: '',
                    // onDutyTime: '20年',
                    // education: '河北工业大学 硕士',
                    // practiceBackground: '河北建筑工程学院副教授，硕士生导师，物联网工程教研室主任，张家口市物联网技术创新中心负责人。先后负责省市级科研项目多项，发表EI等高水平论文多篇，具有丰富的计算机领域科学研究、软件开发及项目管理经验。',
                    // responsibility: '战略规划，投融资',
                    // titleEnglish: 'Si-YaChao',
                    // position: '总经理',
                    // show: true,
                    // animation: '',

                    id: 0,
                    title: '徐小君',
                    image: require('../../assets/image/xu.jpg'),
                    onDutyTime: '20年',
                    education: '北京邮电大学 博士',
                    practiceBackground: '美国上市公司KLA 北京分公司负责人。曾供职于超星数字图书馆软件开发。先后负责多个图像处理及人工智能项目，参加NIST-TRECVID评测。具有丰富的软件开发及项目管理经验。',
                    responsibility: '研发管理，运营管理',
                    titleEnglish: 'Xu-XiaoJun',
                    position: '研发部经理',
                    show: true,
                    animation: '',
                }
            }
        },
        watch:{
            //窗口高度变化
            screenHeight (val) {
                this.bannerHeight = val
            }
        },
        created() {
        },
        mounted() {
            // 监听窗口发生变化
            this.screenWidth = document.body.clientWidth;
            this.screenHeight = document.body.clientHeight;
            window.onresize = () => {
                return (() => {
                    this.screenWidth = document.body.clientWidth;
                    this.screenHeight = document.body.clientHeight;
                })();
            };
            
        },
        methods: {
            toDetail(item){
                if (!item.path) return
                this.$router.push(item.path)
            },
            // 关于我们：通过函数改变数据从而达到视图的改变
            toggleFun(p){
                let that = this
                var leftBox = document.querySelector('.moveLeftBox');
                var rightBox = document.querySelector('.moveRightBox');
                
                that.userList = that.userList.map((item,index,array) => {
                    if( index === array.length -1 && p === 1 ) {
                        item = array[0]
                        // 移除动画类，以便动画只播放一次
                        // setTimeout(function() {
                        //     leftBox.style.transition = '2s'; // 新位置
                        //     leftBox.style.left = '100%'; // 新位置
                        // }, 0); // 500毫秒后移除动画效果
                        // setTimeout(function() {
                        //     leftBox.style.transition = '0s'; // 新位置
                        //     leftBox.style.left = '-1%'; // 新位置
                        // }, 2000);
                    }else if( index === 0 && p === -1 ) {
                        item = array[array.length-1];
                        // // 移除动画类，以便动画只播放一次
                        // setTimeout(function() {
                        //     rightBox.style.transition = '2s'; // 新位置
                        //     rightBox.style.right = '100%'; // 新位置
                        // }, 0); // 500毫秒后移除动画效果
                        // setTimeout(function() {
                        //     rightBox.style.transition = '0s'; // 新位置
                        //     rightBox.style.right = '-1%'; // 新位置
                        // }, 2000);
                    }else{
                        item = array[index+p];
                    }
                    return item;
                })

                for (let i = 0; i < that.userList.length; i++) {
                    if (i == 0) {
                        that.aboutUsData = that.userList[i]
                        that.userList[i].show = true
                    }else {
                        that.userList[i].show = false
                    }
                }
                
            },
            caseEnter(item,index) {
                item.animateBtn = 'animate__zoomIn'
                item.animateTitle = 'animate__fadeInUp'
                item.show = 'block'
            },
            caseLeave(item,index) {
                item.animateBtn = 'animate__zoomOut'
                item.animateTitle = 'animate__fadeOutDown'
            },
            lineEnter(item,index) {
                this.styleIndex = index
                this.styleLine = 'horizontalLineHover roundBoxHover titleBoxHover'
                if (index == 5) {
                    this.titleIndex = 0
                }else if (index == 23) {
                    this.titleIndex = 1
                }else if (index == 41) {
                    this.titleIndex = 2
                }else if (index == 59) {
                    this.titleIndex = 3
                }else if (index == 77) {
                    this.titleIndex = 4
                }
            },
            lineLeave(item,index) {
                this.styleIndex = null
                this.styleLine = ''
                this.titleIndex = null
            },
            qqIconEnter() {
                this.qqIcon = 'icon-QQHover'
            },
            qqIconLeave() {
                this.qqIcon = 'icon-QQ'
            },
            weixinIconEnter() {
                this.weixinIcon = 'icon-weixinHover'
            },
            weixinIconLeave() {
                this.weixinIcon = 'icon-weixin'
            },
            jumpPage(item,index) {
                if (index == 5) {
                    this.$refs.fullpage.api.moveTo(1, 0);
                }else if (index == 23) {
                    this.$refs.fullpage.api.moveTo(2, 0);
                }else if (index == 41) {
                    this.$refs.fullpage.api.moveTo(3, 0);
                }else if (index == 59) {
                    this.$refs.fullpage.api.moveTo(4, 0);
                }else if (index == 77) {
                    this.$refs.fullpage.api.moveTo(5, 0);
                }
            },
            jumpTitlePage(item,index) {
                if (index == 0) {
                    this.$refs.fullpage.api.moveTo(1, 0);
                }else if (index == 1) {
                    this.$refs.fullpage.api.moveTo(2, 0);
                }else if (index == 2) {
                    this.$refs.fullpage.api.moveTo(3, 0);
                }else if (index == 3) {
                    this.$refs.fullpage.api.moveTo(4, 0);
                }else if (index == 4) {
                    this.$refs.fullpage.api.moveTo(5, 0);
                }
            },
            // 轮播图时间
            obtainState(val) {

            },
            afterLoad: function(origin, destination, direction) {
                this.anchorTitle = destination.anchor
                if (destination.anchor == 'banner') {
                    this.logo = require('@/assets/image/logo/red_white.png')
                    this.phoneColor = '#FFFFFF'
                    this.lineColor = '#FFFFFF'
                    this.roundNumber = 6
                }else if (destination.anchor == 'adout') {
                    this.logo = require('@/assets/image/logo/red_black.png')
                    this.phoneColor = '#E93329'
                    this.lineColor = '#D2D2D2'
                    this.roundNumber = 24
                }else if (destination.anchor == 'character') {
                    this.logo = require('@/assets/image/logo/red_black.png')
                    this.phoneColor = '#FFFFFF'
                    this.lineColor = '#D2D2D2'
                    this.roundNumber = 42
                }else if (destination.anchor == 'product') {
                    this.logo = require('@/assets/image/logo/allWhite.png')
                    this.phoneColor = '#E93329'
                    this.lineColor = '#FFFFFF'
                    this.roundNumber = 60
                }else if (destination.anchor == 'cooperate') {
                    this.logo = require('@/assets/image/logo/allWhite.png')
                    this.phoneColor = '#FFFFFF'
                    this.lineColor = '#FFFFFF'
                    this.roundNumber = 78
                }
            },
            next() {
                // 向下滚动一页
                this.$refs.fullpage.api.moveSectionDown();
            },
            moveTo(pages) {
                this.$refs.fullpage.api.moveTo(pages, 1);
            },

        }
    }
</script>

<style lang="scss" scoped>
::v-deep .fp-watermark {
    display: none !important;
}
.goods_item {
    width: 100% !important;
    height: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
}
::v-deep .goods_item > .el-form-item__content {
    width: 100% !important;
    height: 100% !important;
}
::v-deep .goods_item > .el-form-item__content .el-input{
    width: 100% !important;
    height: 100% !important;
}
::v-deep .goods_item > .el-form-item__content .el-input .el-input__wrapper {
    width: 100% !important;
    height: 100% !important;
    background-color: rgba($color: #FFFFFF, $alpha: 0) !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px 0px !important;
}
::v-deep .goods_item > .el-form-item__content .el-input .el-input__inner{
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
    color: #FFFFFF !important;
}
::v-deep .goods_item > .el-form-item__content .el-textarea{
    width: 100% !important;
    height: 100% !important;
}
::v-deep .goods_item > .el-form-item__content .el-textarea .el-input__wrapper {
    width: 100% !important;
    height: 100% !important;
    background-color: rgba($color: #FFFFFF, $alpha: 0) !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px 0px !important;
}
::v-deep .goods_item > .el-form-item__content .el-textarea .el-textarea__inner{
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
    color: #FFFFFF !important;
    box-shadow: 0px 0px 0px 0px !important;
    background-color: rgba($color: #FFFFFF, $alpha: 0) !important;

}
</style>