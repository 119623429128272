<template>
  <div class="about">
    <div class="headrBox">

      <common-header></common-header>

    </div>
    <!-- 头部banner -->
    <div class="headerDanner">

      <!-- logo 和 电话 -->
      <div class="logoAndPhone">

        <div class="logoBox" :class="'animate__animated' + '  ' + titleAnimation">
          <img src="../../assets/image/logo/allWhite.png" alt="">
        </div>

        <div class="phoneBox" :class="'animate__animated' + '  ' + titleAnimation">
          <i class="iconfont icon-dianhua"> 17717712179</i>
        </div>

      </div>

      <!-- 标题 -->
      <div class="titleBox">
        <span style="display: block;" :class="'animate__animated' + '  ' + titleAnimation">ABOUT</span>
      </div>

      <!-- 内容 -->
      <div class="titleContentBox">
        <span style="display: block;height: 26px;font-size: 26px;font-family: MicrosoftYaHei;color: #FFFFFF;line-height: 0px;" :class="'animate__animated' + '  ' + titleAnimation">云察科技优秀的互联网服务提供商。我们已经在互联网行业摸索爬滚近2个年头了！</span>
      </div>

      <!-- 导航 -->
      <div class="navBox">
        <div class="iconBox">
          <div class="iconfontShare icon-share"></div>

        </div>

      </div>

    </div>

    <div class="locationBox">
      <span style="height: 12px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 14px;">当前位置：</span>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="contentBox">
      <div style="height: 40px;font-size: 40px;color: #333333;line-height: 22px;text-align: center;">关于我们</div>
      <div style="width: 64px;height: 16px;font-size: 16px;font-family: MicrosoftYaHei, MicrosoftYaHei;font-weight: bold;color: #333333;line-height: 14px;">公司简介</div>
      <p/>
      <text style="width: 1440px;height: 14px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">河北云察科技有限公司(简称"云察")成立于2022年9月5日，注册资本为500万元人民币，公司位于河北省张家口市高新区纬三路3号新东亚财富中心B座8楼26号主营: 翻译服务、开发、机械设备、进行市场调查、市场调研。</text>
      <p/>
      <div style="width: 64px;height: 16px;font-size: 16px;font-family: MicrosoftYaHei, MicrosoftYaHei;font-weight: bold;color: #333333;line-height: 14px;">企业文化</div>
      <p/>
      <span style="width: 169px;height: 14px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">诚信：诚利天下，信赢未来</span>
      <span style="width: 169px;height: 14px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;margin-left: 64px;">专业：业有专攻，精益求精</span>
      <span style="width: 169px;height: 14px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;margin-left: 64px;">共赢：志同道合，互利共赢</span>
      <p/>

      <div style="width: 64px;height: 16px;font-size: 16px;font-family: MicrosoftYaHei, MicrosoftYaHei;font-weight: bold;color: #333333;line-height: 14px;">团队介绍</div>
      <p/>

      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">司亚超（总经理）</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">职责:战略规划，投融资</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">从业经验:20年</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">教育背景:河北工业大学-硕士</div>
      <text style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">
        从业背景:河北建筑工程学院副教授，硕士生导师，物联网工程教研室主任，张家口市物联网技术创新中心负责人，先后负责省市级科研项目多项，发表EI等高水平论文多篇，具有丰富的计算机领域科学研究、软件开发及项目管理经验
      </text>
      <p/>

      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">徐小军(研发部经理)</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">职责: 研发管理，运营管理</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">从业经验:20年</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">教育背景:北京邮电大学-博士</div>
      <text style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">
        从业背景:美国上市公司KLA北京分公司负责人。曾供职于超星数字图书馆软件开发。先后负责多个图像处理及人工智能项目，参加NIST-TRECVID评测。具有丰富的软件开发及项目管理经验
      </text>
      <p/>

      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">赵明瞻(Al部经理)</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">职责:人工智能算法研究</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">从业经验:20年</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">教育背景:北京科技大学-博士</div>
      <text style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">
        从业背景:从事人工智能算法开发多年，先后参加民政部及河北省科技厅多个项目，具有丰富的算法开发及项目管理经验
      </text>
      <p/>

      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">付江龙(技术部经理)</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">职责:技术研发，系统开发</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">从业经验:9年</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">教育背景:西北工业大学-硕士</div>
      <text style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">
        从业背景:从事软件开发多年，先后参与国家“核高基”项目、河北省科技厅项目等多个项目，具有丰富的软件开发及项目管理经验
      </text>
      <p/>

      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">周旭(技术部经理)</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">职责:技术研发，系统开发</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">从业经验:5年</div>
      <div style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">教育背景:河北建筑工程学院</div>
      <text style="height: 22px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 22px;">
        从事软件开发多年，具有丰富的软件开发及项目管理经验。
      </text>
      <div style="height: 100px;"></div>
    </div>
    <conmmon-bottom></conmmon-bottom>

  </div>

</template>

<script>
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import 'animate.css';

export default {
  components: {
    ConmmonBottom,
    CommonHeader
  },
  data() {

  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  box-sizing: border-box;

  .headrBox {
    width: 64px;
    height: 100%;
    position: fixed;
    right: 0%;
    top: 0%;
    z-index: 999;
  }

  .headerDanner {
    width: 100%;
    height: 384px;
    // 上右下左
    padding: 27px 103px 27px 39px;
    box-sizing: border-box;
    background-image: url(../../assets/image/aboutUs-bj.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    .logoAndPhone {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;

      .logoBox {
        width: calc(71px + 86px);
        height: 100%;
        position: relative;
        overflow: hidden;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
        }
      }

      .phoneBox {
        height: 100%;

        i {
          font-size: 16px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 60px;
          text-align: center;
        }
      }
    }

    .titleBox {
      font-size: 36px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .titleContentBox {
      width: 960px;
      font-size: 26px;
      font-family: MicrosoftYaHei;
      line-height: 0px;
      color: #FFFFFF;
      position: absolute;
      left: 50%;
      top: 65%;
      transform: translate(-50%, -50%);
    }

    .navBox {
      width: 100%;
      height: 48px;
      position: absolute;
      left: 0%;
      bottom: 0%;
      border-top: 1px solid rgba($color: #F5F5F6, $alpha: 0.3);
      .iconBox{
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10%;
        background-color: red;
      }
    }
  }
  .locationBox{
    margin-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
  }
  .contentBox {
    margin-top: 87px;
    padding-left: 10%;
    padding-right: 10%;
  }

}

</style>
