<template>
    <div class="cooperation">

        <div class="headrBox">

            <common-header></common-header>

        </div>

        <div class="cooperationContent">
            <!-- 头部banner -->
            <div class="headerDanner">
                
                <!-- logo 和 电话 -->
                <div class="logoAndPhone">

                    <div class="logoBox" :class="'animate__animated' + '  ' + titleAnimation">
                        <img src="../../assets/image/logo/allWhite.png" alt="">
                    </div>

                    <div class="phoneBox" :class="'animate__animated' + '  ' + titleAnimation">
                        <i class="iconfont icon-dianhua"> 133-3118-3155</i>
                    </div>

                </div> 

                <!-- 标题 -->
                <div class="titleBox">
                    <span style="display: block;">CONTACT</span>
                    <span class="titleInfo" style="display: block;">云察科技有限公司为您准备好了您喜欢的咖啡和舒适的环境赶紧通过以下方式找到我们。</span>
                </div>

                <!-- 导航 -->
                <div class="navBox">

                    <div class="shareBox">

                        <i class="iconfont icon-share2"></i>

                    </div>

                </div>

            </div>

            <div class="middleBox">

                <div class="middleHeader">
                    <div class="addressWord">当前位置：</div>
                    <el-breadcrumb separator=">">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>招商合作</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <div class="middleContent">


                    <div class="leftInfo">

                        <div class="titleBox">联系我们</div>

                        <div class="companyInfo">

                            <div class="companyLeft">

                                <div class="singleBox">公司地址</div>
                                <div class="singleBoxs">河北省张家口市高新区纬三路3号<br/>新东亚财富中心B座8楼26号</div>
                                <div class="singleBox">工作时间</div>
                                <div class="singleBoxs">（周一至周五9:00—18:00）</div>

                            </div>

                            <div class="companyRight">

                                <div class="singleFlex">

                                    <div class="title">联络电话</div>
                                    <div class="answer">13331183155（徐经理）</div>

                                </div>

                                <div class="singleFlex">

                                    <div class="title">电子邮箱</div>
                                    <div class="answer">13331183155@163.com</div>

                                </div>


                                <div class="singleFlex">

                                    <div class="title">官方网站</div>
                                    <div class="answer">www.hbyuncha.com</div>

                                </div>

                                <div class="singleFlex">

                                    <div class="title">区域邮编</div>
                                    <div class="answer">075000</div>

                                </div>

                            </div>


                        </div>

                        <div 
                            class="mapBox"
                            @mouseenter="lineEnter"
                            @mouseleave="lineLeave">
                            <div class="mapColor" :style="{zIndex: (indexShow == false ? '10' : '0') }"></div>
                            <div id="mapContainer"></div>


                        </div>

                    </div>
                    <div class="middleLine"></div>
                    <div class="rightContact">

                        <div class="titleBox">留言给我们</div>

                        <div class="singleFelxBox">

                            <el-form :rules="rules" :model="ruleForm" ref="ruleForm" :inline="true">
                 
                                <div class="singleFelxBox">

                                    <div class="singleXiao">

                                        <div class="singleTitle">您的姓名</div>
    
                                        <div class="singleContent">
                                            <el-form-item class="goods_item">
                                                <el-input :border="false" v-model="ruleForm.name"></el-input>
                                            </el-form-item>
                                        </div>

                                    </div>

                                    <div class="singleLocate">

                                        <div class="singleTitle">您的电话</div>

                                        <div class="singleContent">
                                            <el-form-item class="goods_item">
                                                <el-input :border="false" v-model="ruleForm.phone"></el-input>
                                            </el-form-item>
                                        </div>

                                    </div>

                                </div>

                                <div class="singleOne">

                                    <div class="singleTitle">留言给我们</div>

                                    <div class="singleContent">
                                        <el-form-item class="goods_item">
                                            <el-input type="textarea" :border="false" v-model="ruleForm.message"></el-input>
                                        </el-form-item>
                                    </div>

                                </div>

                                <div class="singleCheck">

                                    <el-form-item>
                                        <el-checkbox 
                                            label="提交即代表认可河北云察科技有限公司用户协议，我们会严格保护用户信息" 
                                            v-model="ruleForm.type"></el-checkbox>
                                    </el-form-item>

                                </div>

                                <div class="sending">

                                    <div class="sendingBtn"></div>

                                </div> 
                                <!-- 
                                <div class="singleLocate">

                                    <div class="singleTitle">您的电话</div>

                                    <div class="singleContent">
                                        <el-form-item class="goods_item">
                                            <el-input :border="false" v-model="ruleForm.phone"></el-input>
                                        </el-form-item>
                                    </div>

                                </div>

                                <div class="singleLocateTwo">
                                    <div class="singleTitle">留言给我们</div>

                                    <div class="singleContent">
                                        <el-form-item class="goods_item">
                                            <el-input type="textarea" :border="false" v-model="ruleForm.message"></el-input>
                                        </el-form-item>
                                    </div>
                                </div> 


                                <div class="sending">

                                    <div class="sendingBtn"></div>

                                </div> -->

                            </el-form>

                        </div>


                    </div>


                </div>


            </div>

            <!-- 底部 -->
            <div class="bottomBox">
                <conmmon-bottom></conmmon-bottom>
            </div>
            
        </div>
        

    </div>
</template>

<script>
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import '../../styles/mainModule/cooperation.scss'
import AMapLoader from '@/utils/AMapLoader';
export default {
    components: {
        ConmmonBottom,
        CommonHeader
    },
    data() {
        return {
            map: null,
            indexShow: false,
            ruleForm: {
                name: '',
                phone: '',
                message: '',
                type: false
            },
            rules: {

            },
        }
    },
    mounted() {
        this.initMap();
    },
    methods: {
        lineEnter() {
            this.indexShow = true
        },
        lineLeave() {
            this.indexShow = false
        },
        async initMap() {
            await AMapLoader.load();
            // 初始化地图对象
            const map = new window.AMap.Map('mapContainer', {
                viewMode: '2D', // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D',
                zoom: 16, //初始化地图层级  114.88901  40.76660
                center: [114.88901, 40.76660],//初始化地图中心点
            });
   
            this.map = map;
            let markers1 = new window.AMap.Marker({
                // 图标尺寸
                size: new AMap.Size(25, 34),
                // 图标的取图地址
                image: "https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
                // 图标所用图片大小
                imageSize: new AMap.Size(135, 40),
                position: [114.88901, 40.76660],
                // 图标取图偏移量
                imageOffset: new AMap.Pixel(-9, -3)
            });
            this.map.add(markers1);
            // 添加地图控件等操作...
        }
    }
}
</script>

<style lang="scss" scoped>
.goods_item {
    width: 100% !important;
    height: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
}
::v-deep .goods_item > .el-form-item__content {
    width: 100% !important;
    height: 100% !important;
}
::v-deep .goods_item > .el-form-item__content .el-input{
    width: 100% !important;
    height: 100% !important;
}
::v-deep .goods_item > .el-form-item__content .el-input .el-input__wrapper {
    width: 100% !important;
    height: 100% !important;
    background-color: rgba($color: #FFFFFF, $alpha: 0) !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px 0px !important;
}
::v-deep .goods_item > .el-form-item__content .el-input .el-input__inner{
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
    color: #999999 !important;
}
::v-deep .goods_item > .el-form-item__content .el-textarea{
    width: 100% !important;
    height: 100% !important;
}
::v-deep .goods_item > .el-form-item__content .el-textarea .el-input__wrapper {
    width: 100% !important;
    height: 100% !important;
    background-color: rgba($color: #FFFFFF, $alpha: 0) !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px 0px !important;
}
::v-deep .goods_item > .el-form-item__content .el-textarea .el-textarea__inner{
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
    color: #999999 !important;
    box-shadow: 0px 0px 0px 0px !important;
    background-color: rgba($color: #FFFFFF, $alpha: 0) !important;

}
//设置选中后的文字颜色
::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
// color: #039F9E !important;
color: #656565 !important;
}
//设置选中后对勾框的边框和背景颜色
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
border-color: #E93329 !important;
background-color:#E93329 !important;
}
//设置checkbox获得焦点后，对勾框的边框颜色
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner{
border-color: #E93329 !important;
}
// 设置鼠标经过对勾框，对勾框边框的颜色
::v-deep .el-checkbox__inner:hover{
border-color: #E93329 !important;
}

</style>