import AMapLoader from '@amap/amap-jsapi-loader';
 
export default {
  load() {
    return new Promise((resolve) => {
      // 设置高德地图API的key值
      const key = 'd0d5a8a98f51c5440eef9bb47058ec24';
      
      // 加载高德地图JS API脚本
      AMapLoader.load({
        key,
        version: '2.0',
        willReadFrequency: true,
        plugins: ['AMap.Geolocation'],
      }).then(() => {
        resolve();
      });
    });
  },
};