<template>
  <div class="about">

    <div class="headrBox">

      <common-header></common-header>

    </div>

    <div class="headerBanner">

      <common-header-banner 
        :backgroundImage="backgroundImage"
        :title="title"
        :titleContent="titleContent">
      </common-header-banner>

    </div>

    <div class="contentBox">

      <div class="locationBox">
        <span>当前位置：</span>
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>案例</el-breadcrumb-item>
          <el-breadcrumb-item>政务服务</el-breadcrumb-item>
          <el-breadcrumb-item>科技网站建设-英迈琪</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="projectShow">

        <!-- 介绍 -->
        <div class="introduceBox">

          <div class="projectName">河北云察科技有限公司为<span>英迈琪</span>提供专业网站定制服务</div>


          <div class="typeBox">

            <div class="typeTitle">网站类型：</div>
            <div class="typeContent">平面设计网站</div>

          </div>

          <div class="typeBox">

            <div class="typeTitle">网站语言：</div>
            <div class="typeContent">中文</div>

          </div>

          <div class="typeBox">

            <div class="typeTitle">所属行业：</div>
            <div class="typeContent">维权/政务服务</div>

          </div>

          <div class="introduceWeb">网站介绍：北京英迈琪科技有限公司成立于2020-01-07，法定代表人为段宇，注册资本为500万元人民币，统一社会信用代码为91110111MA01PR657T，企业地址位于北京市丰台区海鹰路6号院26号楼-1层B1032，所属行业为科技推广和应用服务业，经营范围包含：技术开发、技术咨询（中介除外）、技术转让、技术推广、技术服务；软件开发；组织文化艺术交流活动（不含演出）；市场调查；企业形象策划、设计；承办展览展示；会议服务；翻译服务；计算机系统服务；商标服务；版权服务；经济信息咨询；销售计算机软硬件及辅助设备、机械设备、安全技术防范产品、电子产品；电脑图文设计；工程设计。（企业依法自主选择经营项目，开展经营活动；工程设计以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）。北京英迈琪科技有限公司目前的经营状态为存续（在营、开业、在册）。</div>

          <!-- 图片内容 -->
          <div class="imageContent">

            <div class="webLogo">

              <img src="../../assets/image/yingmaiqi/icon-logo.png" alt="">

            </div>

            <div class="bannerOne">

              <div class="imageOne"></div>
              <div class="imagetwo"></div>

            </div>

            <div class="bannerTwo">

              <div class="bannerTwoTitle">

                <div class="titleEnglish">PAGE DISPLAY</div>

                <div class="titleChinese">页面展示</div>

              </div>

              <div class="backBox">

                <div class="imageThree"></div>

                <div class="bottomBack">

                  <div class="bottomBackBox">

                    <div class="thanksBox">THANKS OF WATCHING</div>

                    <div class="logoBox">
                      <div class="logoBoxTwo">
                        <img src="../../assets/image/logo/allWhite.png" alt="">
                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div> 

    </div>

    <div class="CommonMoreCases">
      <common-more-cases></common-more-cases>
    </div>

    <conmmon-bottom></conmmon-bottom>

  </div>

</template>

<script>
import '../../styles/case/yingmaiqi.scss'
import CommonHeaderBanner from '@/components/CommonHeaderBanner.vue'
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import CommonMoreCases from '@/components/CommonMoreCases.vue'
import 'animate.css';
export default {
  components: {
    ConmmonBottom,
    CommonHeader,
    CommonHeaderBanner,
    CommonMoreCases
  },
  data() {
    return{
        backgroundImage: require('@/assets/image/yingmaiqi/case_p1.png'),
        title: '科技网站建设-英迈琪',
        titleContent: '科技网站建设·张家口网站建设'
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>