<template>

  <div class="tortMonitor">
    <div class="headrBox">

      <common-header></common-header>

    </div>

    <div class="headerBanner">

      <common-header-banner 
        :backgroundImage="backgroundImage"
        :title="title"
        :titleContent="titleContent">
      </common-header-banner>

    </div>

    <div class="contentBox">

      <div class="locationBox">
        <span>当前位置：</span>
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>案例</el-breadcrumb-item>
          <el-breadcrumb-item>商务服务</el-breadcrumb-item>
          <el-breadcrumb-item>商务服务网站建设-侵权检测及电子证据系统</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="projectShow">

        <!-- 介绍 -->
        <div class="introduceBox">

          <div class="projectName">河北云察科技有限公司为<span>英迈琪</span>提供专业<span @click="jumpWeb">侵权检测及电子证据系统</span></div>


          <div class="typeBox">

            <div class="typeTitle">网站类型：</div>
            <div class="typeContent">平面设计网站</div>

          </div>

          <div class="typeBox">

            <div class="typeTitle">网站语言：</div>
            <div class="typeContent">中文</div>

          </div>

          <div class="typeBox">

            <div class="typeTitle">所属行业：</div>
            <div class="typeContent">维权/政务服务</div>

          </div>

          <div class="introduceWeb">网站介绍：北京英迈琪科技有限公司成立于2020-01-07，法定代表人为段宇，注册资本为500万元人民币，统一社会信用代码为91110111MA01PR657T，企业地址位于北京市丰台区海鹰路6号院26号楼-1层B1032，所属行业为科技推广和应用服务业，经营范围包含：技术开发、技术咨询（中介除外）、技术转让、技术推广、技术服务；软件开发；组织文化艺术交流活动（不含演出）；市场调查；企业形象策划、设计；承办展览展示；会议服务；翻译服务；计算机系统服务；商标服务；版权服务；经济信息咨询；销售计算机软硬件及辅助设备、机械设备、安全技术防范产品、电子产品；电脑图文设计；工程设计。（企业依法自主选择经营项目，开展经营活动；工程设计以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）。北京英迈琪科技有限公司目前的经营状态为存续（在营、开业、在册）。</div>

          <!-- 图片内容 -->
          <div class="imageContent">

            <div class="webLogo">

              <img src="../../assets/image/qinquanjiance/case-logo.png" alt="">

            </div>

            <div class="bannerOne">

              <div class="imageOne"></div>
              <div class="imagetwo"></div>

            </div>

            <div class="bannerTwo">

              <div class="bannerTwoTitle">

                <div class="titleEnglish">PAGE DISPLAY</div>

                <div class="titleChinese">页面展示</div>

              </div>

              <div class="backBox">

                <div class="imageThree"></div>

                <div class="bottomBack">

                  <div class="bottomBackBox">

                    <div class="thanksBox">THANKS OF WATCHING</div>

                    <div class="logoBox">
                      <div class="logoBoxTwo">
                        <img src="../../assets/image/logo/allWhite.png" alt="">
                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div> 

    </div>

    <div class="CommonMoreCases">
      <common-more-cases></common-more-cases>
    </div>

    <conmmon-bottom></conmmon-bottom>
  </div>
  <!-- <div class="about">
    <div class="headrBox">

      <common-header></common-header>

    </div>
    <conmmon-detail-bottom
      :backgroundImage="backgroundImage"
      title="商务服务网站建设-侵权检测及电子证据系统"
      titleContent="商务服务网站建设·张家口网站建设"
    />

    <div class="locationBox">
      <span style="height: 12px;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 14px;">当前位置：</span>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案例</el-breadcrumb-item>
        <el-breadcrumb-item>商务服务</el-breadcrumb-item>
        <el-breadcrumb-item>商务服务网站建设-侵权检测及电子证据系统</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="background: #F5F5F6;">

        <div class="contentBox">
        <div class="title">
            <div><a href="#">河北云察科技有限公司</a>为英迈琪提供专业侵权检测及电子证据系统</div>
            <div>网站类型：平面设计网站</div>
            <div>网站语言：中文</div>
            <div>网站类型：商务服务</div>
            <p>网站介绍：北京英迈琪科技有限公司成立于2020-01-07，法定代表人为段宇，注册资本为500万元人民币，统一社会信用代码为91110111MA01PR657T，企业地址位于北京市丰台区海鹰路6号院26号楼-1层B1032，所属行业为科技推广和应用服务业，经营范围包含：技术开发、技术咨询（中介除外）、技术转让、技术推广、技术服务；软件开发；组织文化艺术交流活动（不含演出）；市场调查；企业形象策划、设计；承办展览展示；会议服务；翻译服务；计算机系统服务；商标服务；版权服务；经济信息咨询；销售计算机软硬件及辅助设备、机械设备、安全技术防范产品、电子产品；电脑图文设计；工程设计。（企业依法自主选择经营项目，开展经营活动；工程设计以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）。北京英迈琪科技有限公司目前的经营状态为存续（在营、开业、在册）。</p>
        </div>
        <div style="padding:80px 0;text-align:center"> 
            <img src="../../assets/image/qinquanjiance/case-logo.png" alt="">
        </div>
        <div class="contentImgBox">
            <div class="img-top-box"> <img   src="../../assets/image/qinquanjiance/case_p2.png" alt=""></div>
            <div class="img-bot"> <img class="img1" style="width:100%" src="../../assets/image/qinquanjiance/case_p3.png" alt=""></div>
        </div>
        <div>
            <div class="title-img">
                 页面展示
            </div>
            <div class="zhanshi-img">
                <img src="../../assets/image/qinquanjiance/case_p4.png" alt="">
            </div>
        </div>
        <div class="bottom">
            <div class="title-bto">THANKS OF WATCHING</div>
            <div><img src="../../assets/image/logo/allWhite.png" alt=""></div>
        </div>

    </div>
        <common-more-cases></common-more-cases>

    </div>
    <conmmon-bottom></conmmon-bottom>
  </div> -->

</template>

<script>
import '../../styles/case/tortMonitor.scss'
import CommonHeaderBanner from '@/components/CommonHeaderBanner.vue'
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import CommonMoreCases from '@/components/CommonMoreCases.vue'
import 'animate.css';

export default {
  components: {
    ConmmonBottom,
    CommonHeader,
    CommonHeaderBanner,
    CommonMoreCases
  },
  data() {
    return{
        backgroundImage: require('@/assets/image/qinquanjiance/case_p1.png'),
        title: '商务服务网站建设-侵权检测及电子证据系统',
        titleContent: '商务服务网站建设·张家口网站建设'
    }
  },
  methods: {
    jumpWeb() {
      window.open('http://50417ax093.qicp.vip', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
// .about {
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   background: #F5F5F6;
//  .headrBox {
//     width: 64px;
//     height: 100%;
//     position: fixed;
//     right: 0%;
//     top: 0%;
//     z-index: 999;
//   }


//   .locationBox{
//     margin-top: 50px;
//     padding-left: 10%;
//     padding-right: 10%;
//     display: flex;
//   }
//   .contentBox {
//     background: #FFFFFF;
//     margin:  0  10%;
//     margin-top: 35px;
//     padding:40px 35px;

//     .title{
//         font-size: 22px;
//         font-weight: 400;
//         font-family: MicrosoftYaHei;
//         color: #212121;
        
//     }
//     .contentImgBox{
//         position: relative;
//         margin-top: 220px;
//         .img-top-box{
//                position: absolute;
//             left: 50%;
//             top: -220px;
//              transform: translateX(-50%);
//             z-index: 78;
        
//         }
//         .img-bot{
//             width: 100%;
//             height: 568px;
//             position: relative;
//             overflow: hidden;
//             .img1 {
//                 position: absolute;
//                 top: 50%;
//                 left: 50%;
//                 transform: translate(-50%,-50%) ;
//                 max-width: 100%;
//                 max-height: 100%;
//                 display: block;
//                 z-index: 7;
//                 margin: auto;
//             }
//         }
//     }
//     .title-img{
//         font-size: 32px;
//         font-weight: 700;
//         font-family: MicrosoftYaHei;
//         color: #212121;
//         height: 100px;
//         line-height: 130px;
//         text-align: center;
//     }
//     .zhanshi-img{
//         width: 100%;
//         img{
//              width: 100%;
//         }
//     }

//   }
//       .bottom{
//         height: 400PX;
//         width: 100%;
//           display: flex;
//         justify-content: center;
//         align-items: center;
//         text-align: center;
//          flex-direction: column;
//         background: #252D37 ;
//         .title-bto{
//             font-size: 48px;
//             font-family: SourceHanSansCN, SourceHanSansCN;
//             font-weight: 400;
//             color: #FFFFFF;
//         }
//         img{
//             width: 176px;
//             height: 56px;
//         }
//     }

// }

</style>
