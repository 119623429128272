<template>
    <div class="sendByPost">

        <div class="headrBox">

            <common-header></common-header>

        </div>

        <div class="headerBanner">

            <common-header-banner 
            :backgroundImage="backgroundImage"
            :title="title"
            :titleContent="titleContent">
            </common-header-banner>

        </div>

        <div class="contentBox">

            <div class="locationBox">
                <span>当前位置：</span>
                <el-breadcrumb separator=">">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>案例</el-breadcrumb-item>
                    <el-breadcrumb-item>快运</el-breadcrumb-item>
                    <el-breadcrumb-item>快运APP建设-张运速递APP/小程序</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="projectShow">

                <!-- 介绍 -->
                <div class="introduceBox">

                    <div class="projectName">河北云察科技有限公司为<span>张运速递</span>提供了APP设计、小程序开发等一系列服务。</div>


                    <div class="typeBox">

                        <div class="typeTitle">网站类型：</div>
                        <div class="typeContent">APP/小程序</div>

                    </div>

                    <div class="typeBox">

                        <div class="typeTitle">网站语言：</div>
                        <div class="typeContent">中文</div>

                    </div>

                    <div class="typeBox">

                        <div class="typeTitle">所属行业：</div>
                        <div class="typeContent">速运/物流行业</div>

                    </div>

                    <div class="introduceWeb">网站介绍：张运集团是一家具有公路客、货运输，城市公交，汽车赁，现代物流，旅游出租，整车销售，汽车检修，驾驶培训，广告传媒，新能源建设等综合服务能力于一体的大型运输企业。目前公司下属22个分公司，15个全资子公司，4个控股子公司、4个参股公司， 6个成员单位，拥有职工3863人，资产总额5.3亿元，年创利税653万元。是全省道路运输骨干企业，也是张家口市规模最大、经济实力最强的国有大型公路运输企业。具备道路旅客运输和道路货物运输二级资质，2002年全面通过ISO质量管理体系认证。是全国重点联系道路运输企业。</div>

                    <!-- 图片内容 -->
                    <div class="imageContent">

                        <div class="webLogo">

                            <img src="../../assets/image/zysdAPP/case_logo.png" alt="">

                        </div>

                        <div class="bannerOne">

                            <div class="imageOne"></div>
                            <div class="imagetwo"></div>

                        </div>

                        <div class="bannerTwo">

                            <div class="bannerTwoTitle">

                                <div class="titleEnglish">PAGE DISPLAY</div>

                                <div class="titleChinese">页面展示</div>

                            </div>

                            <div class="backBox">

                                <div class="imageThree">

                                    <div class="imageListBox">

                                        <div class="listContent">
                                            <div class="card" v-for="(item,index) in cardList" :key="'only' + index">
                                                <div class="cardImage">
                                                    <img :src="item.url" alt="">
                                                </div>
                                            </div>
                
                                            <div class="historylList" v-for="item in ( historyRow - cardList.length % historyRow)" :key="item">
                                                <div v-if="cardList.length % historyRow > 0"></div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="bottomBack">

                                    <div class="bottomBackBox">

                                        <div class="thanksBox">THANKS OF WATCHING</div>

                                        <div class="logoBox">
                                            <div class="logoBoxTwo">
                                                <img src="../../assets/image/logo/allWhite.png" alt="">
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div> 

        </div>

        <div class="CommonMoreCases">
            <common-more-cases></common-more-cases>
        </div>

        <conmmon-bottom></conmmon-bottom>

    </div>

</template>

<script>
import '../../styles/case/sendByPost.scss'
import CommonHeaderBanner from '@/components/CommonHeaderBanner.vue'
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import CommonMoreCases from '@/components/CommonMoreCases.vue'
import 'animate.css';

export default {
  components: {
    ConmmonBottom,
    CommonHeader,
    CommonHeaderBanner,
    CommonMoreCases
  },
  data() {
    return{
        backgroundImage: require('@/assets/image/zysdAPP/case_p1.png'),
        title: '快运APP建设-张运速递',
        titleContent: '科技APP建设·张家口APP/小程序建设',
        cardList: [
            {
                url: require('@/assets/image/zysdAPP/case_p4.png')
            },
            {
                url: require('@/assets/image/zysdAPP/case_p5.png')
            },
            {
                url: require('@/assets/image/zysdAPP/case_p6.png')
            },
            {
                url: require('@/assets/image/zysdAPP/case_p7.png')
            },
            {
                url: require('@/assets/image/zysdAPP/case_p8.png')
            },
            {
                url: require('@/assets/image/zysdAPP/case_p9.png')
            }
        ],
        historyRow: 3,
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
