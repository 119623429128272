<template>
    <div class="CommonMoreCases">
        <div class="bottomNav">

            <div class="shareBox">

                <i class="iconfont icon-caidan"></i>

            </div>

        </div>

        <div class="otherProducts">

            <div class="labelBox">

                <div class="moreCases">推荐案例</div>
                <div class="openIcon">
                    更多案例
                    <i class="iconfont icon-more"></i>
                </div>

            </div>

            <div class="caseList">

                <div class="cardBox" :class="'animate__animated' + '  ' + cardAnimation">

                    <div class="card" v-for="(item,index) in cardList" :key="'only' + index" @click="toDetail(item)">

                        <div class="cardBanner">
                            <img :src="item.image" alt="">
                        </div>

                        <div class="cardContent">

                            <div class="cardTime">{{item.time}}</div>

                            <div class="cardTitle">{{item.title}}</div>

                            <div class="cardLine"></div>

                            <div class="cardWord">{{item.word}}</div>

                            <div class="cardBtn">READ MORE</div>
                        </div>

                    </div>

                    <div class="historylList" v-for="item in ( historyRow - cardList.length % historyRow)" :key="item">
                        <div v-if="cardList.length % historyRow > 0"></div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
import '../styles/assembly/CommonMoreCases.scss'
export default {
    data() {
        return {
           historyRow: 5,
            cardList: [
               {
                    id: 4,
                    image: require('@/assets/image/case_p5.png'),
                    time: '2023-12-22 15:50',
                    path:'/patentData',
                    title: '网站建设-云察专利服务平台',
                    word: '在线专利信息分析系统是由国家知识产权局为各地方知识产权局组织开发建设的，基于互联网环境使用的专利信息分析系统。本系统可实现二次开发、自由配置等功能，满足系统功能的特色化、个性化；用户的待分析数据只要符合系统数据格式要求，就可以导入系统进行分析，且默认自带全领域数据库数据格式导入接口。本系统分析功能包括数据清理与整合、申请人归并；基础分析（申请人、区域、技术领域、法律状态分析等）、高级分析（自定义分析）、定制分析（核心技术分析、申请人合作分析、技术发展及衍变趋势分析）及专业化模型分析（企业专利实力分析、企业发展定位分析等）等功能。本系统由国家知识产权局部署到全国各地方知识产权局，由国家知识产权局负责统一系统运维，各地方知识产权局负责系统的具体使用和推广。',
                },
                {
                    id: 1,
                    path:'/yingmaiqi',
                    image: require('@/assets/image/case_p2.png'),
                    time: '2023-12-22 15:50',
                    title: '网站建设-英迈琪科技有限公司',
                    word: '北京英迈琪科技有限公司成立于2020-01-07，法定代表人为段宇，注册资本为500万元人民币，统一社会信用代码为91110111MA01PR657T，企业地址位于北京市丰台区海鹰路6号院26号楼-1层B1032，所属行业为科技推广和应用服务业，经营范围包含：技术开发、技术咨询（中介除外）、技术转让、技术推广、技术服务；软件开发；组织文化艺术交流活动（不含演出）；市场调查；企业形象策划、设计；承办展览展示；会议服务；翻译服务；计算机系统服务；商标服务；版权服务；经济信息咨询；销售计算机软硬件及辅助设备、机械设备、安全技术防范产品、电子产品；电脑图文设计；工程设计。（企业依法自主选择经营项目，开展经营活动；工程设计以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）。北京英迈琪科技有限公司目前的经营状态为存续（在营、开业、在册）。',
                },
            ],
            keyID: 0
        }
    },
    created() {
        this.cardAnimation = 'animate__lightSpeedInLeft'

    },
    methods: {
          toDetail(item){
            if (!item.path) return
            this.$router.push(item.path)
        }
    }
}
</script>

<style lang="scss" scoped>
    // .CommonHeaderbox{
    //   width: 100%;
    //   height: 720px;
    //   margin-top:40px  ;
    //   box-sizing: border-box;
    //   padding-right: 64px;
    //   font-size: 14px;
    //   font-family: SourceHanSansCN, SourceHanSansCN;
    //   font-weight: 400;
    //   color: #333333;
    //   .fenye{
    //     padding: 0 11%;
    //     display: flex;
    //     justify-content: space-between;
    //     height: 48px;
    //     line-height: 48px;
    //     border-top: 1px solid #999999 ;
    //     border-bottom: 1px solid #999999 ;
    //     .hover1{
    //       width: 48px;
    //       text-align: center;
    //       i{
    //         font-size: 24px;
    //       }
    //     }
    //     .hover1:hover{
    //         background: #E93329;
    //      }
    //   }
    //   .label{
    //     display: flex;
    //     justify-content: space-between;
    //     padding:0 calc(56px + 64px) 0 56px;
    //     height: 60px;
    //     line-height: 60px;
    //     .moreCases{
    //       font-size: 26px;
    //       font-family: SourceHanSansCN, SourceHanSansCN;
    //       font-weight: bold;
    //       color: #333333;
    //     }
    //     .openIcon{
    //       display: flex;
    //       align-items: center;
    //       i{
    //         color: red;
    //         font-size: 28px;
    //       }
    //     }
    //   }
    //   .contentBox {
    //         width: 100%;
    //         padding:60px calc(56px + 64px) 60px 56px;
    //         background-color: #F5F5F6;
    //         box-sizing: border-box;
    //         .cardBox {
    //             width: 100%;
    //             display: flex;
    //             flex-wrap: wrap;
    //             justify-content: space-between;
    //             .card {
    //                 width: 418px;
    //                 height: 472px;
    //                 margin-bottom: 24px;
    //                 background-color: #FFFFFF;
    //                 box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    //                 .cardBanner {
    //                     width: 100%;
    //                     height: 288px;
    //                     position: relative;
    //                     overflow: hidden;
    //                     img {
    //                         width: 100%;
    //                         height: 100%;
    //                         transition: transform 0.3s ease-in-out;
    //                     }
    //                 }
    //                 .cardContent {
    //                     width: 100%;
    //                     height: calc(100% - 288px);
    //                     padding: 20px;
    //                     box-sizing: border-box;
    //                     .cardTime {
    //                         width: 100%;
    //                         font-size: 14px;
    //                         font-family: MicrosoftYaHei;
    //                         color: #999999;
    //                     }
    //                     .cardTitle {
    //                         font-size: 16px;
    //                         font-family: MicrosoftYaHei, MicrosoftYaHei;
    //                         font-weight: bold;
    //                         color: #333333;
    //                         line-height: 14px;
    //                         width: 100%;
    //                         margin-top: 10px;
    //                     }
    //                     .cardLine {
    //                         width: 20px;
    //                         height: 2px;
    //                         background: #E93329;
    //                         margin-top: 10px;
    //                     }
    //                     .cardWord {
    //                         width: 100%;
    //                         margin-top: 10px;
    //                         font-size: 14px;
    //                         font-family: MicrosoftYaHei;
    //                         color: #999999;
    //                         line-height: 22px;
    //                         overflow: hidden;
    //                         text-overflow: ellipsis;
    //                         display: -webkit-box;
    //                         -webkit-line-clamp: 2;
    //                         line-clamp: 2;
    //                         -webkit-box-orient: vertical;
    //                     }
    //                     .cardBtn {
    //                         width: 100%;
    //                         margin-top: 15px;
    //                         font-size: 14px;
    //                         font-family: MicrosoftYaHei;
    //                         color: #E93329;
    //                         line-height: 14px;
    //                     }
    //                 }
    //             }
    //             .card:hover {
    //                 cursor: pointer;
    //                 // background-color: rgba($color: $auxiliary-color, $alpha: 0.6);
    //                 .cardBanner {
    //                     img {
    //                         transform: scale(1.3);
    //                     }
    //                 }
    //                 // .cardContent {
    //                 //     .cardTime {
    //                 //         color: #E93329;
    //                 //     }
    //                 //     .cardTitle {
    //                 //         color: #E93329;
    //                 //     }
    //                 //     .cardWord {
    //                 //         color: #E93329;
    //                 //     }
    //                 // }
    //             }
       
    //             .historylList {
    //                 content: ""; 
    //                 width: 418px;
    //                 border: 1px solid transparent;
    //                 overflow: hidden;
    //             }
    //         }
    //         .paginationBox {
    //             width: 100%;
    //             display: flex;
    //             justify-content: center;
    //         }
    //     }
    // }
        
</style>