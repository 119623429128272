<template>
    <div class="ConmmonBottom">

        <div class="contentBottom">

            <div class="leftTabulation">

                <div class="columnBox">

                    <div class="columnTitle">产品中心:</div>

                    <div class="columnContent">

                        <div 
                            class="columnWord" 
                            v-for="(item,index) in productList" 
                            :key="'only' + index"
                            @click="jumpPage(item)">

                            <span class="columnWordBox">{{item.title}}</span>

                            <div class="line">|</div>

                        </div>

                    </div>

                </div>

                <div class="columnBox">

                    <div class="columnTitle">联系电话:</div>

                    <div class="columnWords">13331183155(徐经理)</div>

                </div>

                <div class="columnBox">

                    <div class="columnTitle">总部地址:</div>

                    <div class="columnWords">河北省张家口市高新区纬三路3号新东亚财富中心B座8楼26号</div>

                </div>

                <div class="columnBox">

                    <div class="singleBox">Copyright @2023河北云察科技有限公司版权所有</div>
                    <div class="singleImage">
                        <img src="../assets/image/icon-biao.png">
                    </div>
                    <div class="singleICPBox">
                        <a href="https://beian.miit.gov.cn/" style="color: #FFFFFF;">冀ICP备2023012129号</a>
                    </div>

                </div>


            </div>

            <div class="rightCode">
                <div class="qrCodeBox">
                    <div class="qrCode">
                        <img src="../assets/image/qrCode.png">
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import '@/styles/assembly/ConmmonBottom.scss'
export default {
    data() {
        return {
            productList: [
                {
                    id: 0,
                    title: '五社善治',
                    path: '/goshaApp',
                },
                {
                    id: 0,
                    title: '张运速递',
                    path: '/sendByPost',
                },
                {
                    id: 0,
                    title: '侵权监测及电子证据系统',
                    path: '/tortMonitor',
                },
                {
                    id: 0,
                    title: '云察专利数据服务平台',
                    path: '/patentData',
                }
            ]
        }
    },
    methods: {
        jumpPage(item) {
            this.$router.push({path: item.path})
        },
    }
}
</script>

<style>

</style>