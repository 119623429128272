<template>
    <div class="product">

        <div class="headrBox">

            <common-header></common-header>

        </div>

        <div class="productContent">

            <!-- 头部banner -->
            <div class="headerDanner">
                
                <!-- logo 和 电话 -->
                <div class="logoAndPhone">

                    <div class="logoBox" :class="'animate__animated' + '  ' + titleAnimation">
                        <img src="../../assets/image/logo/allWhite.png" alt="">
                    </div>

                    <div class="phoneBox" :class="'animate__animated' + '  ' + titleAnimation">
                        <i class="iconfont icon-dianhua"> 133-3118-3155</i>
                    </div>

                </div> 

                <!-- 标题 -->
                <div class="titleBox">
                    <span style="display: block;" :class="'animate__animated' + '  ' + titleAnimation">ALL CASES</span>
                </div>

                <!-- 导航 -->
                <div class="navBox">



                </div>

            </div>

            <!-- 中心列表区域 -->
            <div class="contentBox">

                <div class="cardBox" :class="'animate__animated' + '  ' + cardAnimation">

                    <div class="card" v-for="(item,index) in cardList" :key="'only' + index" @click="toDetail(item)">

                        <div class="cardBanner">
                            <img :src="item.image" alt="">
                        </div>

                        <div class="cardContent">

                            <div class="cardTime">{{item.time}}</div>

                            <div class="cardTitle">{{item.title}}</div>

                            <div class="cardLine"></div>

                            <div class="cardWord">{{item.word}}</div>

                            <div class="cardBtn">READ MORE</div>
                        </div>

                    </div>

                    <div class="historylList" v-for="item in ( historyRow - cardList.length % historyRow)" :key="item">
                        <div v-if="cardList.length % historyRow > 0"></div>
                    </div>

                </div>

                <!-- <div class="paginationBox" :class="'animate__animated' + '  ' + paginationAnimation">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="1000">
                    </el-pagination>
                </div> -->

            </div>

            <!-- 底部 -->
            <div class="bottomBox">
                <conmmon-bottom></conmmon-bottom>
            </div>

        </div>

    </div>
</template>

<script>
import '../../styles/mainModule/product.scss'
import ConmmonBottom from '@/components/ConmmonBottom.vue'
import CommonHeader from '@/components/CommonHeader.vue'
import 'animate.css';
export default {
    components: { 
        ConmmonBottom,
        CommonHeader
    },
    data() {
        return {
            titleAnimation: '',
            titleAnimation: '',
            paginationAnimation: '',
            historyRow: 5,
            cardList: [
                {
                    id: 0,
                    path:'/goshaApp',
                    image: require('@/assets/image/case_p1.png'),
                    time: '2023-12-22 15:50',
                    title: '网站建设-五社善治',
                    word: '五社善治APP是一款用于志愿服务为主体的应用。志愿者可以建设组织团队,进行活动的发布和报名;为了更多志愿者加入其中,五社善治还联合当地的超市进行一些优惠特价服务提供给志愿者。',
                },
                {
                    id: 1,
                    path:'/yingmaiqi',
                    image: require('@/assets/image/case_p2.png'),
                    time: '2023-12-22 15:50',
                    title: '网站建设-英迈琪科技有限公司',
                    word: '北京英迈琪科技有限公司成立于2020-01-07，法定代表人为段宇，注册资本为500万元人民币，统一社会信用代码为91110111MA01PR657T，企业地址位于北京市丰台区海鹰路6号院26号楼-1层B1032，所属行业为科技推广和应用服务业，经营范围包含：技术开发、技术咨询（中介除外）、技术转让、技术推广、技术服务；软件开发；组织文化艺术交流活动（不含演出）；市场调查；企业形象策划、设计；承办展览展示；会议服务；翻译服务；计算机系统服务；商标服务；版权服务；经济信息咨询；销售计算机软硬件及辅助设备、机械设备、安全技术防范产品、电子产品；电脑图文设计；工程设计。（企业依法自主选择经营项目，开展经营活动；工程设计以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）。北京英迈琪科技有限公司目前的经营状态为存续（在营、开业、在册）。',
                },
                {
                    id: 2,
                    path:'/tortMonitorApplet',
                    image: require('@/assets/image/case_p3.png'),
                    time: '2023-12-22 15:50',
                    title: '商务服务小程序建设-侵权监测小程序',
                    word: '系统采用最优的AI算法和训练数据，应用最新的深度学习模型，并与电子存证机构达成合作，可实现侵权线索快速检索、实时监控、确权取证三大功能。',
                },
                {
                    id: 3,
                    path:'/tortMonitor',
                    image: require('@/assets/image/case_p4.png'),
                    time: '2023-12-22 15:50',
                    title: '商务网站建设-侵权电子证据系统',
                    word: '北京英迈琪科技有限公司成立于2020-01-07，法定代表人为段宇，注册资本为500万元人民币，统一社会信用代码为91110111MA01PR657T，企业地址位于北京市丰台区海鹰路6号院26号楼-1层B1032，所属行业为科技推广和应用服务业，经营范围包含：技术开发、技术咨询（中介除外）、技术转让、技术推广、技术服务；软件开发；组织文化艺术交流活动（不含演出）；市场调查；企业形象策划、设计；承办展览展示；会议服务；翻译服务；计算机系统服务；商标服务；版权服务；经济信息咨询；销售计算机软硬件及辅助设备、机械设备、安全技术防范产品、电子产品；电脑图文设计；工程设计。（企业依法自主选择经营项目，开展经营活动；工程设计以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）。北京英迈琪科技有限公司目前的经营状态为存续（在营、开业、在册）。',
                },
                {
                    id: 4,
                    image: require('@/assets/image/case_p5.png'),
                    time: '2023-12-22 15:50',
                    path:'/patentData',
                    title: '网站建设-云察专利服务平台',
                    word: '在线专利信息分析系统是由国家知识产权局为各地方知识产权局组织开发建设的，基于互联网环境使用的专利信息分析系统。本系统可实现二次开发、自由配置等功能，满足系统功能的特色化、个性化；用户的待分析数据只要符合系统数据格式要求，就可以导入系统进行分析，且默认自带全领域数据库数据格式导入接口。本系统分析功能包括数据清理与整合、申请人归并；基础分析（申请人、区域、技术领域、法律状态分析等）、高级分析（自定义分析）、定制分析（核心技术分析、申请人合作分析、技术发展及衍变趋势分析）及专业化模型分析（企业专利实力分析、企业发展定位分析等）等功能。本系统由国家知识产权局部署到全国各地方知识产权局，由国家知识产权局负责统一系统运维，各地方知识产权局负责系统的具体使用和推广。',
                },
                // {
                //     id: 5,
                //     image: require('@/assets/image/case_p6.png'),
                //     time: '2023-12-22 15:50',
                //     title: '志愿服务小程序建设-五社善治',
                //     word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                // },
                // {
                //     id: 6,
                //     image: require('@/assets/image/case_p7.png'),
                //     time: '2023-12-22 15:50',
                //     title: '教育网站建设-墨以AI教学',
                //     word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                // },
                {
                    id: 7,
                    path:'/sendByPost',
                    image: require('@/assets/image/case_p8.png'),
                    time: '2023-12-22 15:50',
                    title: '运输服务建设-张运速递',
                    word: '张云速递APP是一款快递物流综合服务APP。客货邮已形成地网和信息网两网合一、可覆盖国内大部分地区。客货邮采用直营的经营模式,对分支机构实施统一经营、统一管理。',
                },
                // {
                //     id: 8,
                //     image: require('@/assets/image/case_p9.png'),
                //     time: '2023-12-22 15:50',
                //     title: '教育网站建设-河北建筑工程学院',
                //     word: '网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治网站建设-五社善治',
                // },
                {
                    id: 9,
                    path:'/sendByPostApplet',
                    image: require('@/assets/image/case_p10.png'),
                    time: '2023-12-22 15:50',
                    title: '运输服务小程序建设-张运速递',
                    word: '张运集团是一家具有公路客、货运输，城市公交，汽车赁，现代物流，旅游出租，整车销售，汽车检修，驾驶培训，广告传媒，新能源建设等综合服务能力于一体的大型运输企业。目前公司下属22个分公司，15个全资子公司，4个控股子公司、4个参股公司， 6个成员单位，拥有职工3863人，资产总额5.3亿元，年创利税653万元。是全省道路运输骨干企业，也是张家口市规模最大、经济实力最强的国有大型公路运输企业。具备道路旅客运输和道路货物运输二级资质，2002年全面通过ISO质量管理体系认证。是全国重点联系道路运输企业。',
                },
                {
                    id: 10,
                    path:'http://182.42.140.235:31001/',
                    image: require('@/assets/image/AIBanner.png'),
                    time: '2023-12-22 15:50',
                    title: '云察AIGC',
                    word: '一款以文生图和以图生图的工具网站',
                }

            ]
        }
    },
    created() {
        // this.titleAnimation = 'animate__fadeInDown'
        // this.cardAnimation = 'animate__lightSpeedInLeft'
        // this.paginationAnimation = 'animate__lightSpeedInRight'
    },
    methods:{
        toDetail(item){
            if (!item.path && item.id != 10) {
                this.$router.push(item.path)
            }else {
                window.open(item.path, '_blank')
            }
        }
    }
}
</script>

<style>

</style>