import { createRouter, createWebHistory } from 'vue-router'
// 首页
import home from '@/views/homeIndex'
// 关于我们
import adout from '../views/adout'
// 招商合作
import cooperation from '../views/cooperation'
// 产品中心
import product from '../views/product'

/**
 * @case 案例
 * @path 五社善治APP goshaApp
 * @path 云察专利数据 patentData
 * @path 张运速递 sendByPost
 * @path 张运速递小程序 sendByPostApplet
 * @path 侵权监测及电子证据系统 tortMonitor
 * @path 侵权监测小程序 tortMonitorApplet
 * @path 英迈琪 yingmaiqi
 * */ 
import goshaApp from '../views/case/goshaApp'
import patentData from '../views/case/patentData'
import sendByPost from '../views/case/sendByPost'
import sendByPostApplet from '../views/case/sendByPostApplet'
import tortMonitor from '../views/case/tortMonitor'
import tortMonitorApplet from '../views/case/tortMonitorApplet'
import yingmaiqi from '../views/case/yingmaiqi'

/**
 * @case 首页二
 * @path homeIndexGsap
*/
import homeIndexGsap from '../views/homeIndexGsap'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/homeIndexGsap',
    name: 'homeIndexGsap',
    component: homeIndexGsap
  },
  {
    path: '/adout',
    name: 'adout',
    component: adout
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: cooperation
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/goshaApp',
    name: 'goshaApp',
    component: goshaApp
  },
  {
    path: '/patentData',
    name: 'patentData',
    component: patentData
  },
  {
    path: '/sendByPost',
    name: 'sendByPost',
    component: sendByPost
  },
  {
    path: '/sendByPostApplet',
    name: 'sendByPostApplet',
    component: sendByPostApplet
  },
  {
    path: '/tortMonitor',
    name: 'tortMonitor',
    component: tortMonitor
  },
  {
    path: '/tortMonitorApplet',
    name: 'tortMonitorApplet',
    component: tortMonitorApplet
  },
  {
    path: '/yingmaiqi',
    name: 'yingmaiqi',
    component: yingmaiqi
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes]
});


export default router
